import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { TenantBasedLinkData, TransactionStatus } from "Services/api/services/interfaces";
import { linkServiceInstance } from "Services/api/services/services";
import { JsendFail } from "Types/jsend";
import { showSnackbarErrorMessage, showSnackbarErrorsObject } from "Types/snackbar";
import { FormikHelpers, useFormik } from "formik";
import { ProviderContext, useSnackbar } from "notistack";
import { ServiceLogo } from "../ServiceLogo/ServiceLogo";

export function TenantBasedServiceLinkDialog({
  open,
  handleClose = () => {},
  service,
  logoUrl,
}: {
  open: boolean;
  handleClose?: () => void;
  service: "NeosCRM" | "NeosGoal";
  logoUrl: string;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const initialValues: TenantBasedLinkData = {
    username: "",
    password: "",
    tenant: "",
    service,
  };
  const { values, handleChange, handleBlur, handleSubmit, isSubmitting } = useFormik({
    initialValues,
    onSubmit: onLinkServiceInstance(enqueueSnackbar, handleClose),
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <ServiceLogo serviceName={service} logoUrl={logoUrl} sx={{ width: 54, height: 54 }} />
          <div>{`Link a ${service} instance`}</div>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <TextField
              hiddenLabel
              name="username"
              placeholder="Username"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
            />
            <TextField
              type="password"
              hiddenLabel
              name="password"
              placeholder="Password"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            <TextField
              hiddenLabel
              name="tenant"
              placeholder={service === "NeosCRM" ? "Company" : "Domain"}
              variant="outlined"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.tenant}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={isSubmitting}>
            Link
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function onLinkServiceInstance(enqueueSnackbar: ProviderContext["enqueueSnackbar"], handleClose?: () => void) {
  return async (values: TenantBasedLinkData, { setSubmitting }: FormikHelpers<TenantBasedLinkData>) => {
    const result = await linkServiceInstance(values);

    if (result.status === "fail") {
      displayLinkServiceInstanceFailErrors(result, enqueueSnackbar);

      setSubmitting(false);
      return;
    }

    enqueueSnackbar("Service Instance successfully linked", { variant: "success" });
    handleClose && handleClose();
  };
}

function displayLinkServiceInstanceFailErrors(
  result: JsendFail<string | Record<string, string[]> | TransactionStatus>,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"]
) {
  if (typeof result.data === "string") {
    showSnackbarErrorMessage(enqueueSnackbar, result.data);
  } else if (typeof result.data.code === "number") {
    enqueueSnackbar(result.data.description, {
      variant: "error",
    });
  } else {
    showSnackbarErrorsObject(enqueueSnackbar, result.data as Record<string, string[]>);
  }
}
