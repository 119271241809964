import { neosAccountRequest } from "../config";
import { StringJsendResponse, stringJsendResponseDecoder } from "../interfaces";
import {
  Address,
  GetAddressResponse,
  GetAddressesResponse,
  getAddressResponseDecoder,
  getAddressesResponseDecoder,
} from "./interfaces";

export const getAddresses = async (): Promise<GetAddressesResponse> => {
  const { data } = await neosAccountRequest.get<GetAddressesResponse>(`addresses/?type=full`);

  return getAddressesResponseDecoder.verify(data);
};

export const getAddress = async (id: number | string): Promise<GetAddressResponse> => {
  const { data } = await neosAccountRequest.get<GetAddressesResponse>(`addresses/${id}/`);

  return getAddressResponseDecoder.verify(data);
};

export const createAddress = async (address: Address): Promise<GetAddressResponse> => {
  const { data } = await neosAccountRequest.post<GetAddressResponse>(`addresses/`, address);

  return getAddressResponseDecoder.verify(data);
};

export const updateAddress = async (address: Address): Promise<GetAddressResponse> => {
  // istanbul ignore next
  if (!address.id) {
    throw new Error("Address id is required to update an address");
  }
  const { data } = await neosAccountRequest.put<GetAddressResponse>(`addresses/${address.id}/`, address);

  return getAddressResponseDecoder.verify(data);
};

export const deleteAddress = async (id: number | string): Promise<StringJsendResponse> => {
  const { data } = await neosAccountRequest.delete<StringJsendResponse>(`addresses/${id}/`);

  return stringJsendResponseDecoder.verify(data);
};
