import { array, Decoder, number, object, string } from "decoders";
import { JsendSuccess, jsendSuccessDecoder } from "Types/jsend";

export interface ListInvoice {
  id: string;
  created: string;
  description: string;
  amount: number;
  status: string;
  lines: {
    id: string;
    description: string;
    amount: number;
    quantity: number;
  }[];
}

const listInvoiceDecoder: Decoder<ListInvoice> = object({
  id: string,
  created: string,
  description: string,
  amount: number,
  status: string,
  lines: array(
    object({
      id: string,
      description: string,
      amount: number,
      quantity: number,
    })
  ),
});

export type ListInvoicesResponse = JsendSuccess<ListInvoice[]>;

export const listInvoicesResponseDecoder: Decoder<ListInvoicesResponse> = jsendSuccessDecoder(
  array(listInvoiceDecoder)
);
