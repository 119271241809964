import ClearIcon from "@mui/icons-material/Clear";
import { CssBaseline } from "@mui/material";
import { grey } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BackdropProvider } from "@nc/neoscloud-common-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import BillingContainer from "Containers/BillingContainer/BillingContainer";
import { MainContainer } from "Containers/MainContainer/MainContainer";
import useSingleEffect from "Hooks/useSingleEffect";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarKey, SnackbarProvider } from "notistack";
import { Account } from "Pages/Account/Account";
import { AddAddress } from "Pages/AddAddress/AddAddress";
import { AddressBook } from "Pages/AddressBook/AddressBook";
import { Dashboard } from "Pages/Dashboard/Dashboard";
import { EditAddress } from "Pages/EditAddress/EditAddress";
import { Home } from "Pages/Home/Home";
import { Invoices } from "Pages/Invoices/Invoices";
import { LoginPage } from "Pages/LoginPage/LoginPage";
import { NeosDrive } from "Pages/NeosDrive/NeosDrive";
import { NeosMail } from "Pages/NeosMail/NeosMail";
import { NotFound } from "Pages/NotFound/NotFound";
import { Payment } from "Pages/Payment/Payment";
import { Registration } from "Pages/Registration/Registration";
import { Restore } from "Pages/Restore/Restore";
import { ServiceLoginPage } from "Pages/ServiceLoginPage/ServiceLoginPage";
import { ServicePage } from "Pages/ServicePage/ServicePage";
import { Subscriptions } from "Pages/Subscriptions/Subscriptions";
import React from "react";
import { Route, BrowserRouter as Router, Routes, useNavigate } from "react-router-dom";
import { ReCAPTCHAContextProvider } from "./RecaptchaContext";

const theme = createTheme({
  palette: {
    primary: {
      main: grey[900],
      light: "#484848",
      dark: "#000000",
    },
  },
});

const loggedInPaths: { [k: string]: () => JSX.Element } = Object.freeze({
  "/dashboard": Dashboard,
  "/account": Account,
  "/account/address-book": AddressBook,
  "/account/address-book/add": AddAddress,
  "/account/address-book/edit/:id": EditAddress,
  "/billing/*": Billing,
  "/service/:id": ServicePage,
  "/neosdrive": NeosDrive,
  "/neosmail": NeosMail,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

export function App() {
  const notistackRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    (notistackRef.current as SnackbarProvider).closeSnackbar(key);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ConfirmProvider>
          <SnackbarProvider
            maxSnack={3}
            ref={notistackRef}
            hideIconVariant
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)} aria-label="dismiss alert" component="span">
                <ClearIcon sx={{ color: "#ffffff" }} />
              </IconButton>
            )}
          >
            <BackdropProvider>
              <Router>
                <ReCAPTCHAContextProvider>
                  <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/login" element={<LoginPage />}></Route>
                    <Route path="/signup" element={<Registration />}></Route>
                    <Route path="/restore" element={<Restore />}></Route>
                    {Object.keys(loggedInPaths).map((key) => (
                      <Route key={key} path={key} element={<LoggedInRoute path={key} />}></Route>
                    ))}
                    <Route path="/serviceLogin/:name" element={<ServiceLoginPage />}></Route>
                    <Route path="*" element={<NotFound />}></Route>
                  </Routes>
                </ReCAPTCHAContextProvider>
              </Router>
            </BackdropProvider>
          </SnackbarProvider>
        </ConfirmProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

function LoggedInRoute({ path }: { path: string }) {
  const Element = loggedInPaths[path];
  return (
    <MainContainer>
      <Element />
    </MainContainer>
  );
}

const billingPaths: { [k: string]: { index: number; Element: () => JSX.Element } } = {
  "/": { index: 0, Element: Payment },
  "/payment": { index: 0, Element: Payment },
  "/invoices": { index: 1, Element: Invoices },
  "/subscriptions": { index: 2, Element: Subscriptions },
};

function Billing() {
  return (
    <Routes>
      {Object.keys(billingPaths).map((key) => (
        <Route key={key} path={key} element={<BillingRoute path={key} />}></Route>
      ))}
      <Route path="*" element={<Redirect to="/notfound" />}></Route>
    </Routes>
  );
}

function BillingRoute({ path }: { path: string }) {
  const { index, Element } = billingPaths[path];
  return (
    <BillingContainer index={index}>
      <Element />
    </BillingContainer>
  );
}

function Redirect({ to }: { to: string }) {
  const navigate = useNavigate();

  useSingleEffect(() => navigate(to));
  return <></>;
}
