import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  Container,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MoneyFormat } from "@nc/neoscloud-common-react";
import { useQuery } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { ListInvoice } from "Services/api/invoices/interfaces";
import { getInvoices } from "Services/api/invoices/invoices";
import { Query } from "Shared/Query/Query";
import { Fragment, useState } from "react";

export function Invoices() {
  return (
    <>
      <Container component="header" maxWidth="md" sx={{ marginTop: 2 }}>
        <Typography component="h1" variant="h5" gutterBottom sx={{ pb: "10px" }}>
          Invoices
        </Typography>
      </Container>
      <Container component="section" maxWidth="md" sx={{ marginTop: 2 }}>
        <InvoiceList />
      </Container>
    </>
  );
}

function InvoiceList() {
  const errorMsg = "An error ocurred loading invoices";
  const handleError = useHandleError(errorMsg);

  const result = useQuery({
    queryKey: [getInvoices.name],
    queryFn: async () => {
      const { data } = await getInvoices();
      return data;
    },
    cacheTime: 0,
  });

  return (
    <Query
      result={result}
      OnLoading={() => <Skeleton variant="rectangular" height={200} />}
      onError={() => <div>{`${errorMsg}...`}</div>}
      onSuccess={(invoices) => <InvoiceTable invoices={invoices} />}
      onErrorCallback={(error) => handleError(error)}
    />
  );
}

function InvoiceTable({ invoices }: { invoices: ListInvoice[] }) {
  return (
    <Fragment>
      {invoices.length === 0 && <h4>You don&apos;t have invoices.</h4>}
      {invoices.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 550, textAlign: "center" }} aria-label="Invoices">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Description</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((invoice) => (
                <Row key={invoice.id} invoice={invoice} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  );
}

function Row({ invoice }: { invoice: ListInvoice }) {
  const [open, setOpen] = useState(false);
  const hasLines = invoice.lines.length > 0;

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          {hasLines && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{invoice.description}</TableCell>
        <TableCell align="center">{invoice.created}</TableCell>
        <TableCell align="center">{invoice.status}</TableCell>
        <TableCell align="right">
          <MoneyFormat amount={invoice.amount} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          {hasLines && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Items
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoice.lines.map((line) => (
                      <TableRow key={line.id}>
                        <TableCell>{line.description} </TableCell>
                        <TableCell align="center">{line.quantity} </TableCell>
                        <TableCell align="right">
                          <MoneyFormat amount={line.amount} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          )}
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
