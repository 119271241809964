import { CircularProgress, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";
import { useUserDataContext } from "Containers/MainContainer/UserDataContext";
import { Service } from "Services/api/services/interfaces";
import { getServiceToken, getServices } from "Services/api/services/services";
import { Query } from "Shared/Query/Query";
import { ServiceLogo } from "Shared/ServiceLogo/ServiceLogo";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

export function Dashboard() {
  const result = useQuery({
    queryKey: [getServices.name],
    queryFn: async () => {
      const { data } = await getServices();
      return data;
    },
    cacheTime: 0,
  });

  return (
    <Query
      result={result}
      OnLoading={() => <Loading />}
      onError={() => <Error />}
      onSuccess={(services) => <Services services={services} />}
      onErrorCallback={(error) => {
        console.error(error);
      }}
    />
  );
}

function Loading({ msg = "Loading Applications..." }: { msg?: string }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <CircularProgress />
      <Typography component="h1" variant="h6" gutterBottom>
        {msg}
      </Typography>
    </Box>
  );
}

function Error() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography component="h1" variant="h6" gutterBottom>
        Error fetching applications.
      </Typography>
    </Box>
  );
}

function Services({ services }: { services: Service[] }) {
  const [{ username, subscriptions }] = useUserDataContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [status, setStatus] = useState<"default" | "loading-service">("default");

  if (status === "loading-service") return <Loading msg="Generating Service Token..." />;

  return (
    <Box component="section" sx={{ pb: 5 }}>
      <Typography component="h1" variant="h5" gutterBottom sx={{ pb: "10px" }}>
        Services
      </Typography>
      <Grid container spacing={8}>
        {services.map((service) => {
          const { id, name, url } = service;
          return (
            <Grid key={id} item xs={12} sm={6} md={3}>
              <Service
                service={service}
                onServiceClicked={async () => {
                  if (name.toLowerCase() === "neosdrive" && !subscriptions.neosdrive) {
                    navigate("../neosdrive");
                    return;
                  } else if (name.toLowerCase() === "neosmail" && !subscriptions.neosmail) {
                    navigate("../neosmail");
                    return;
                  }
                  setStatus("loading-service");
                  const result = await getServiceToken(name, username);
                  setStatus("default");

                  if (result.status === "fail") {
                    enqueueSnackbar(result.data);
                    return;
                  }

                  window.open(`${url}?neosAccountToken=${result.data}`, "_blank");
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

interface ServiceProps {
  service: Service;
  onServiceClicked: () => Promise<void>;
}

function Service(props: ServiceProps) {
  const {
    service: { id, name, logoUrl, hasTenant },
    onServiceClicked,
  } = props;
  return (
    <ButtonBase
      {...(hasTenant
        ? { LinkComponent: RouterLink, to: `/service/${id}` }
        : { onClick: () => void onServiceClicked() })}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            width: "54px",
            height: "54px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ServiceLogo serviceName={name} logoUrl={logoUrl} />
        </Box>
        <Typography component="h2" variant="h6">
          {name}
        </Typography>
      </Box>
    </ButtonBase>
  );
}
