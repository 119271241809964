import { PropsWithChildren, RefObject, createContext, useContext, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation } from "react-router-dom";

const reCaptchaSiteKey = process.env.RECAPTCHA_SITE_KEY || "";

const ReCAPTCHAContext = createContext<RefObject<ReCAPTCHA>>(null as unknown as RefObject<ReCAPTCHA>);

export function useReCAPTCHAContext() {
  return useContext(ReCAPTCHAContext);
}

export function ReCAPTCHAContextProvider({ children }: PropsWithChildren) {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const location = useLocation();

  return (
    <ReCAPTCHAContext.Provider value={recaptchaRef}>
      {children}
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={reCaptchaSiteKey}
        size="invisible"
        hidden={location.pathname !== "/signup"}
      />
    </ReCAPTCHAContext.Provider>
  );
}
