import { JsendResponse, JsendSuccess, jsendResponseDecoder, jsendSuccessDecoder } from "Types/jsend";
import { DecoderType, array, boolean, number, object, string } from "decoders";
import { ErrorKeyResponse, errorKeyResponseDecoder } from "../interfaces";

export const paymentMethodDecoder = object({
  id: string,
  created: number,
  default: boolean,
  card: object({ last4: string }),
});

export type PaymentMethod = DecoderType<typeof paymentMethodDecoder>;

export type PaymentMethodsResponse = JsendSuccess<PaymentMethod[]>;
export const paymentMethodsResponseDecoder = jsendSuccessDecoder(array(paymentMethodDecoder));

export type AddPaymentMethodsResponse = JsendResponse<PaymentMethod, ErrorKeyResponse>;
export const addPaymentMethodsResponseDecoder = jsendResponseDecoder(paymentMethodDecoder, errorKeyResponseDecoder);

interface CreatePaymentMethod {
  id: string;
  last4: string;
}

export type CreatePaymentMethodResponse = JsendResponse<CreatePaymentMethod, ErrorKeyResponse>;
