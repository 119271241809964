import { jsendSuccessDecoder } from "Types/jsend";
import { DecoderType, array, boolean, object, string } from "decoders";
import { serviceObject } from "../services/interfaces";

export const servicePlansDecoder = object({
  ...serviceObject,
  plans: array(
    object({
      id: string,
      name: string,
      description: string,
      capacity: string,
      cost: string,
      isShared: boolean,
    })
  ),
});

export const plansResponseDecoder = jsendSuccessDecoder(servicePlansDecoder);

export type PlansResponse = DecoderType<typeof plansResponseDecoder>;
