import { FailedRegisterResponse } from "Services/api/users/interfaces";
import { JsendFail } from "Types/jsend";
import { showSnackbarErrorMessage, showSnackbarErrorsObject } from "Types/snackbar";
import { ProviderContext } from "notistack";

export function handleFailedRegistration(
  result: JsendFail<FailedRegisterResponse>,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"]
) {
  result.data.match({
    left: showSnackbarErrorsObject(enqueueSnackbar),
    right: showSnackbarErrorMessage(enqueueSnackbar),
  });
}
