import { Box } from "@mui/material";
import { FormContainer } from "Containers/FormContainer/FormContainer";
import useSingleEffect from "Hooks/useSingleEffect";
import logo from "Public/LockLogo.png";
import { validSession } from "Services/api/users/users";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { EmailConfirmForm } from "./EmailConfirmForm";
import { RegistrationForm } from "./RegistrationForm";

export type InputNames = "firstName" | "lastName" | "email" | "username" | "password" | "confirm";

export type RegistrationFormData = {
  [key in InputNames]: string;
};

export interface RegistrationRequestData extends Omit<RegistrationFormData, "email" | "username"> {
  email?: string;
  username?: string;
  emailConfirmCode?: string;
}

export function Registration() {
  const [confirmEmail, setConfirmEmail] = useState<boolean>(false);
  const [requestData, setRequestData] = useState<RegistrationRequestData>();
  const navigate = useNavigate();

  useSingleEffect(() => void redirectLoggedInUser(navigate));

  return (
    <FormContainer
      title="Create a Neoscloud Account"
      logo={
        <Box
          src={logo}
          sx={{
            width: "60px",
            paddingBottom: "20px",
          }}
          component="img"
          alt="Nescloud logo"
        />
      }
    >
      {confirmEmail && requestData ? (
        <EmailConfirmForm requestData={requestData} setConfirmEmail={setConfirmEmail} />
      ) : (
        <RegistrationForm setConfirmEmail={setConfirmEmail} requestData={requestData} setRequestData={setRequestData} />
      )}
    </FormContainer>
  );
}

async function redirectLoggedInUser(navigate: NavigateFunction) {
  const isValid = await validSession();
  if (isValid) navigate("../dashboard");
}
