import { Add } from "@mui/icons-material";
import { Button, Card, CardActions, CardContent, Grid, Stack, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteAddress, getAddress, getAddresses } from "Services/api/addresses/addresses";
import { Address } from "Services/api/addresses/interfaces";
import { Query } from "Shared/Query/Query";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export function AddressBook() {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const result = useQuery({
    queryKey: [getAddresses.name],
    queryFn: async () => {
      const { data, status } = await getAddresses();

      // istanbul ignore next
      if (status === "fail") {
        enqueueSnackbar(data, { variant: "error" });
        return [];
      }

      for (const address of data) {
        queryClient.setQueryData<Address>([getAddress.name, address.id], address);
      }
      return data;
    },
  });

  const { mutateAsync: onDelete } = useMutation(deleteAddress, {
    onSuccess: ({ status, data }, id) => {
      // istanbul ignore next
      if (status === "fail") {
        enqueueSnackbar(data, { variant: "error" });
        return;
      }

      queryClient.setQueryData<Address[]>([getAddresses.name], (oldData) => {
        // istanbul ignore next
        if (!oldData) return [];
        return oldData.filter((address) => address.id !== id);
      });
      enqueueSnackbar(data, { variant: "success" });
    },
    onError: (error) => {
      // istanbul ignore
      console.error(error);
      enqueueSnackbar(GENERIC_ERROR_MESSAGE, { variant: "error" });
    },
  });

  return (
    <Fragment>
      <Typography component="h1" variant="h5" gutterBottom sx={{ pb: "10px" }}>
        Address Book
      </Typography>
      <Query
        result={result}
        OnLoading={() => <div>Loading...</div>}
        onError={() => <div>An error ocurred loading addresses...</div>}
        onSuccess={(addresses) => (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4} height={"auto"}>
              <Card
                sx={{ height: "100%" }}
                variant="outlined"
                component={({ children, ...props }) => (
                  <Button {...props} component={Link} sx={{ height: "100%" }}>
                    {children}
                  </Button>
                )}
                fullWidth
                to="add/"
              >
                <CardContent component={Stack} alignItems="center" spacing={2}>
                  <Add fontSize="large" />
                  <Typography variant="h6" component="h2">
                    Add New Address
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {addresses.map((address) => {
              const {
                id,
                name,
                first_name,
                last_name,
                is_default,
                is_company,
                company_name,
                job_title,
                address1,
                address2,
                city,
                state_province,
                zip_postal_code,
                country,
                phone,
                has_phone_ext,
                phone_ext,
                email,
              } = address;
              // istanbul ignore next
              if (!id) return null;
              return (
                <Grid item xs={12} md={6} lg={4} key={address.id} height={"auto"}>
                  <Card
                    variant="outlined"
                    sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around" }}
                  >
                    <CardContent>
                      <Typography variant="h6" component="h2">
                        {name}
                      </Typography>
                      <Typography variant="subtitle2">{is_default && "Default address"}</Typography>
                      <Stack>
                        <Typography variant={"body2"}>
                          {first_name} {last_name}
                        </Typography>
                        {is_company && (
                          <Fragment>
                            <Typography variant={"body2"}>{`Company: ${company_name}`}</Typography>
                            <Typography variant={"body2"}>{`Job title: ${job_title}`}</Typography>
                          </Fragment>
                        )}
                        <Typography variant={"body2"}>
                          {address1} {address2 && `, ${address2}`}
                        </Typography>
                        <Typography variant={"body2"}>
                          {city}; {state_province} {zip_postal_code}
                        </Typography>
                        <Typography variant={"body2"}>
                          {country} tel: {phone}; {has_phone_ext && `ext: ${phone_ext}`}
                        </Typography>
                        <Typography variant={"body2"}>{email}</Typography>
                      </Stack>
                    </CardContent>
                    <CardActions>
                      <Button size="small" component={Link} to={`edit/${id}`} aria-label={`Edit ${name}`}>
                        Edit
                      </Button>
                      <Button
                        size="small"
                        onClick={() =>
                          void confirm({
                            title: "Delete address",
                            description: "Are you sure you want to remove this address?",
                          })
                            .then(() => onDelete(id))
                            .catch(() => {})
                        }
                        aria-label={`Delete ${name}`}
                      >
                        Delete
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      />
    </Fragment>
  );
}
