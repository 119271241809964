import useSingleEffect from "Hooks/useSingleEffect";
import { UserData } from "Services/api/users/interfaces";
import { getUserData, validSession } from "Services/api/users/users";
import { showSnackbarErrorMessage } from "Types/snackbar";
import { useSnackbar } from "notistack";
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const init: UserData = {
  username: "",
  firstName: "",
  lastName: "",
  avatar: "",
  subscriptions: { neosdrive: false, neosmail: false },
};

const UserDataContext = createContext<[UserData, Dispatch<SetStateAction<UserData>>]>([
  init,
  (() => {}) as Dispatch<SetStateAction<UserData>>,
]);

export function useUserDataContext() {
  return useContext(UserDataContext);
}

export function UserDataContextProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<UserData>(init);
  const location = useLocation();

  useSingleEffect(
    () =>
      void (async () => {
        try {
          const isValid = await validSession();
          if (!isValid) {
            navigate(`../login?next=${location.pathname}`);
            return;
          }
          const response = await getUserData();
          if (response.status === "success") setData(response.data);
          else throw response.data.unwrap();
        } catch (error) {
          console.error(error);
          showSnackbarErrorMessage(enqueueSnackbar, "An error occurred while trying to load the user data");
          navigate(`../login?next=${location.pathname}`);
        }
      })()
  );

  return <UserDataContext.Provider value={[data, setData]}>{children}</UserDataContext.Provider>;
}
