import { ButtonBase, Container, Link, Stack, useMediaQuery, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Copyright } from "@nc/neoscloud-common-react";
import logo from "Public/NeoscloudLogo.png";
import { logout } from "Services/api/auth/auth";
import { showSnackbarErrorMessage } from "Types/snackbar";
import { ProviderContext, useSnackbar } from "notistack";
import { MouseEvent, PropsWithChildren, useState } from "react";
import { NavigateFunction, Link as RouterLink, useNavigate } from "react-router-dom";
import { UserDataContextProvider, useUserDataContext } from "./UserDataContext";

export function MainContainer<T>({ children }: PropsWithChildren<T>) {
  return (
    <UserDataContextProvider>
      <Layout>{children}</Layout>
    </UserDataContextProvider>
  );
}

function Layout<T>({ children }: PropsWithChildren<T>) {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [{ firstName, lastName, avatar }] = useUserDataContext();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const nameInitials = (firstName[0] || "").toUpperCase() + (lastName[0] || "").toUpperCase();

  return (
    <Stack sx={{ height: "100vh", justifyContent: "space-between" }}>
      <AppBar position="absolute">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <ButtonBase component={RouterLink} to="/dashboard">
              <Box
                src={logo}
                sx={{
                  height: 39,
                  filter: "invert(1)",
                  position: "relative",
                  top: -5,
                }}
                component="img"
                alt="Nescloud logo"
              />
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1, fontFamily: '"Product Sans",Arial,Helvetica,sans-serif;', padding: "15px 0 0 5px" }}
              >
                Account
              </Typography>
            </ButtonBase>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0 }}
                aria-label="open user menu"
                data-testid="open-user-menu"
              >
                <Avatar {...(avatar ? { src: avatar } : {})}>{nameInitials || null}</Avatar>
                {/* Line below is for testing purposes so element should be hidden */}
                <div style={{ display: "none" }}>{nameInitials || null}</div>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  navigate("../account");
                }}
              >
                <Typography textAlign="center" data-testid="account-link">
                  Account
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  navigate("../billing");
                }}
              >
                <Typography textAlign="center" data-testid="billing-link">
                  Billing
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  void onLogOutClick(enqueueSnackbar, navigate);
                  handleCloseUserMenu();
                }}
              >
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Container component="main" sx={{ py: 12 }}>
        {children}
      </Container>

      <Box
        component="footer"
        sx={{
          display: "flex",
          backgroundColor: "#484848",
          justifyContent: "space-between",
          pl: "24px",
          pr: "24px",
          pt: "10px",
          pb: "10px",
          flexWrap: "wrap",
        }}
      >
        <Stack
          direction="row"
          spacing={5}
          sx={
            isSmall
              ? {
                  flexGrow: 1,
                  justifyContent: "center",
                  pb: "10px",
                }
              : {}
          }
        >
          <Link href="https://neoscloudllc.com/contact-us/" underline="none" sx={{ color: "#ffffff" }}>
            Help
          </Link>

          <Link href="https://neoscloudllc.com/privacy-policy/" underline="none" sx={{ color: "#ffffff" }}>
            Privacy
          </Link>
        </Stack>
        <Copyright sx={{ color: "#ffffff", fontWeight: "bold" }} />
      </Box>
    </Stack>
  );
}

async function onLogOutClick(enqueueSnackbar: ProviderContext["enqueueSnackbar"], navigate: NavigateFunction) {
  try {
    await logout();
    navigate("../login");
  } catch (error) {
    showSnackbarErrorMessage(enqueueSnackbar, "An error occurred while trying to log out");
    console.error(error);
  }
}
