import { array, string, unknown } from "decoders";
import { JsendResponse, jsendResponseDecoder } from "Types/jsend";

import { neosAccountRequest } from "../config";
import {
  LinkNeosCRMResponse,
  linkNeoscrmResponseDecoder,
  Service,
  serviceDecoder,
  ServiceInstance,
  serviceInstanceDecoder,
  ServiceResponse,
  servicesResponseDecoder,
  TenantBasedLinkData,
} from "./interfaces";

export async function getServices(): Promise<ServiceResponse> {
  const { data } = await neosAccountRequest.get<ServiceResponse>("services/");

  return servicesResponseDecoder.verify(data);
}

export async function getService(id: number): Promise<JsendResponse<Service, string>> {
  const { data } = await neosAccountRequest.get<JsendResponse<Service, string>>(`services/${id}/`);

  return jsendResponseDecoder(serviceDecoder, string).verify(data);
}

export async function getServiceByName(name: string): Promise<JsendResponse<Service, string>> {
  const { data } = await neosAccountRequest.get<JsendResponse<Service, string>>(`services/by_name/${name}/`);

  return jsendResponseDecoder(serviceDecoder, string).verify(data);
}

export async function getServiceInstances(serviceId: number): Promise<JsendResponse<ServiceInstance[], unknown>> {
  const { data } = await neosAccountRequest.get<JsendResponse<ServiceInstance[], unknown>>(
    `services/${serviceId}/instances/`
  );
  return jsendResponseDecoder(array(serviceInstanceDecoder), unknown).verify(data);
}

export async function getServiceInstanceByName(
  serviceId: number,
  instanceName: string
): Promise<JsendResponse<ServiceInstance, string>> {
  const { data } = await neosAccountRequest.get<JsendResponse<ServiceInstance, string>>(
    `services/${serviceId}/instances/by_name/${instanceName}/`
  );
  return jsendResponseDecoder(serviceInstanceDecoder, string).verify(data);
}

export async function linkServiceInstance(linkData: TenantBasedLinkData): Promise<LinkNeosCRMResponse> {
  const { data } = await neosAccountRequest.post<LinkNeosCRMResponse>(`services/link/`, linkData);
  return linkNeoscrmResponseDecoder.verify(data);
}

export async function deleteServiceInstance(
  service: string,
  username: string,
  tenant: string
): Promise<JsendResponse<string, string>> {
  const { data } = await neosAccountRequest.post<JsendResponse<string, string>>(`services/delete_service_instance/`, {
    service,
    username,
    tenant,
  });
  return jsendResponseDecoder(string, string).verify(data);
}

export async function getTenantServiceToken(
  service: string,
  username: string,
  tenant: string
): Promise<JsendResponse<string, string>> {
  const { data } = await neosAccountRequest.post<JsendResponse<string, string>>(`services/tenant_service_token/`, {
    service,
    username,
    tenant,
  });

  return jsendResponseDecoder(string, string).verify(data);
}

export async function getServiceToken(service: string, username: string): Promise<JsendResponse<string, string>> {
  const { data } = await neosAccountRequest.post<JsendResponse<string, string>>(`services/service_token/`, {
    service,
    username,
  });

  return jsendResponseDecoder(string, string).verify(data);
}
