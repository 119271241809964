import { useQuery } from "@tanstack/react-query";
import { getAddress } from "Services/api/addresses/addresses";
import { AddressForm, contactFormFieldsInitialValues } from "Shared/AddressForm/AddressForm";
import { Query } from "Shared/Query/Query";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

export function EditAddress() {
  const { id } = useParams();
  // istanbul ignore next
  const addressId = parseInt(id || "");
  const { enqueueSnackbar } = useSnackbar();

  const result = useQuery({
    queryKey: [getAddress.name, addressId],
    queryFn: async () => {
      // istanbul ignore next
      if (!addressId) return contactFormFieldsInitialValues;
      const { data, status } = await getAddress(addressId);

      // istanbul ignore next
      if (status === "fail") {
        enqueueSnackbar(data, { variant: "error" });
        return contactFormFieldsInitialValues;
      }
      return data;
    },
  });

  return (
    <Query
      result={result}
      onSuccess={(data) => {
        return <AddressForm heading="Edit Address" initialValues={data} edit />;
      }}
    />
  );
}
