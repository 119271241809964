import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { None, Option, Some } from "@sniptt/monads/build";
import { Service, ServiceInstance } from "Services/api/services/interfaces";

export interface ServicePageState {
  loadedContent: Option<ServicePageLoadedContent>;
}

export interface ServicePageLoadedContent {
  service: Service;
  serviceInstances: ServiceInstance[];
}

const initialState: ServicePageState = {
  loadedContent: None,
};

const slice = createSlice({
  name: "servicePage",
  initialState,
  reducers: {
    initializeServicePage: () => initialState,
    loadService(
      state,
      {
        payload: { service, serviceInstances },
      }: PayloadAction<{ service: Service; serviceInstances: ServiceInstance[] }>
    ) {
      state.loadedContent = Some({ service, serviceInstances });
    },
    updateInstances(state, { payload: { serviceInstances } }: PayloadAction<{ serviceInstances: ServiceInstance[] }>) {
      state.loadedContent = Some({ service: state.loadedContent.unwrap().service, serviceInstances });
    },
  },
});

export const { initializeServicePage, loadService, updateInstances } = slice.actions;
export default slice.reducer;
