import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useUserDataContext } from "Containers/MainContainer/UserDataContext";
import { UserData, UserPasswordChange } from "Services/api/users/interfaces";
import { clearPhoto, updateNames, updatePassword, updatePhoto } from "Services/api/users/users";
import { validatePassword, validatePasswordConfirm } from "Services/validations";
import { handleFailedJsend } from "Types/snackbar";
import { FormikHelpers, useFormik } from "formik";
import { ProviderContext, useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { formInputConfigs } from "../Registration/RegistrationForm";

interface UserGivenNames {
  firstName: UserData["firstName"];
  lastName: UserData["lastName"];
}

export function Account() {
  return (
    <Stack spacing={4}>
      <BasicInfo />
      <Password />
      <AddressBook />
    </Stack>
  );
}

function BasicInfo() {
  const [{ firstName, lastName, avatar }] = useUserDataContext();
  const [isNamesFormOpen, setIsNamesFormOpen] = useState(false);
  const [isPhotoFormOpen, setIsPhotoFormOpen] = useState(false);
  const nameInitial = (firstName[0] || "").toUpperCase();

  return (
    <Card component="section" variant="outlined" sx={{ borderRadius: "8px", padding: "8px" }}>
      <CardHeader title="Basic info" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              endIcon={<EditIcon />}
              onClick={() => setIsPhotoFormOpen(true)}
              fullWidth={true}
              sx={{ justifyContent: "space-between", textTransform: "inherit" }}
              data-testid="avatar-button"
            >
              <Typography variant="body1">
                <strong>Photo:</strong>
              </Typography>
              <Avatar sx={{ width: 56, height: 56 }} {...(avatar ? { src: avatar } : {})}>
                {nameInitial || null}
              </Avatar>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              endIcon={<EditIcon />}
              onClick={() => setIsNamesFormOpen(true)}
              fullWidth={true}
              sx={{ justifyContent: "space-between", textTransform: "inherit" }}
            >
              <Typography variant="body1">
                <strong>Name:</strong> {`${firstName} ${lastName}`}
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <PhotoFormDialog
          open={isPhotoFormOpen}
          handleClose={() => setIsPhotoFormOpen(false)}
          nameInitial={nameInitial}
        />
        <NamesFormDialog open={isNamesFormOpen} handleClose={() => setIsNamesFormOpen(false)} />
      </CardContent>
    </Card>
  );
}

function onSaveNames(
  data: UserData,
  setNames: Dispatch<SetStateAction<UserData>>,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  handleClose: () => void
) {
  return async ({ firstName, lastName }: UserGivenNames) => {
    try {
      const response = await updateNames(firstName, lastName);

      if (response.status === "fail") {
        handleFailedJsend(response, enqueueSnackbar);
        return;
      }

      setNames({ ...data, ...response.data });
      enqueueSnackbar("Names updated successfully", {
        variant: "success",
      });
      handleClose();
    } catch (error) {
      enqueueSnackbar("A server error occurred", {
        variant: "error",
      });
      console.error(error);
    }
  };
}

function PhotoFormDialog({
  open,
  handleClose,
  nameInitial,
}: {
  open: boolean;
  handleClose: () => void;
  nameInitial: string;
}) {
  const [userData, setUserData] = useUserDataContext();
  const { enqueueSnackbar } = useSnackbar();
  const [src, setSrc] = useState(userData.avatar);
  const [clear, setClear] = useState(false);
  const photoInputId = "avatar";

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const response = clear ? await clearPhoto() : await updatePhoto(data);

      if (response.status === "fail") {
        handleFailedJsend(response, enqueueSnackbar);
        return;
      }
      enqueueSnackbar(response.data, {
        variant: "success",
      });
      setUserData({ ...userData, avatar: src });
      setClear(false);
      handleClose();
    } catch (error) {
      enqueueSnackbar("A server error occurred", {
        variant: "error",
      });
      console.error(error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      setSrc(reader.result as string);
      setClear(false);
    };
  };

  useEffect(() => {
    setSrc(userData.avatar);
    setClear(false);
  }, [userData]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box component="form" noValidate onSubmit={(ev: React.FormEvent<HTMLFormElement>) => void handleSubmit(ev)}>
        <DialogTitle>Change photo</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2} sx={{ mt: 1, alignItems: "center" }}>
            <Avatar sx={{ width: 100, height: 100 }} data-testid="edit-avatar" {...(src ? { src } : {})}>
              {nameInitial || null}
            </Avatar>
            <TextField
              id={photoInputId}
              name={photoInputId}
              label="Photo"
              type="file"
              fullWidth
              sx={{ display: "none" }}
              onChange={handleChange}
            />
            <Button fullWidth={true} onClick={() => document.getElementById(photoInputId)?.click()} variant="contained">
              Upload photo
            </Button>
            {src ? (
              <Button
                fullWidth={true}
                onClick={() => {
                  setClear(true);
                  setSrc("");
                }}
                variant="contained"
              >
                Clear photo
              </Button>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

function NamesFormDialog({ open, handleClose }: { open: boolean; handleClose: () => void }) {
  const [data, setUserData] = useUserDataContext();
  const { firstName, lastName } = data;
  const { enqueueSnackbar } = useSnackbar();

  const validate = ({ firstName, lastName }: UserGivenNames) => {
    const errors = {} as UserData;
    if (!firstName) errors.firstName = "Enter first name";
    if (!lastName) errors.lastName = "Enter last name";

    return errors;
  };

  const { values, setValues, handleChange, handleBlur, handleSubmit, isSubmitting, errors } = useFormik<UserGivenNames>(
    {
      initialValues: { firstName, lastName },
      onSubmit: onSaveNames(data, setUserData, enqueueSnackbar, handleClose),
      validate,
    }
  );

  useEffect(() => {
    void setValues({
      firstName,
      lastName,
    });
  }, [firstName, lastName]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>Change name</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
            <TextField
              id={formInputConfigs.firstName.id}
              name={formInputConfigs.firstName.name}
              label={formInputConfigs.firstName.label}
              required
              fullWidth
              autoComplete="given-name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName}
            />
            <TextField
              id={formInputConfigs.lastName.id}
              name={formInputConfigs.lastName.name}
              label={formInputConfigs.lastName.label}
              required
              fullWidth
              autoComplete="family-name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function Password() {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Card component="section" variant="outlined" sx={{ borderRadius: "8px", padding: "8px" }}>
      <CardHeader title="Password" />
      <CardContent>
        <Button
          variant="outlined"
          endIcon={<EditIcon />}
          onClick={() => setOpen(true)}
          fullWidth={true}
          sx={{ justifyContent: "space-between" }}
        >
          <Typography variant="body1">••••••••</Typography>
        </Button>
        <PasswordFormDialog enqueueSnackbar={enqueueSnackbar} open={open} handleClose={() => setOpen(false)} />
      </CardContent>
    </Card>
  );
}

function onChangePassword(enqueueSnackbar: ProviderContext["enqueueSnackbar"], handleClose: () => void) {
  return async (
    { currentPassword, newPassword, confirmPassword }: UserPasswordChange,

    { resetForm }: FormikHelpers<UserPasswordChange>
  ) => {
    try {
      const response = await updatePassword({ currentPassword, newPassword, confirmPassword });
      if (response.status === "fail") {
        handleFailedJsend(response, enqueueSnackbar);
        return;
      }
      resetForm();
      enqueueSnackbar(response.data, {
        variant: "success",
      });
      handleClose();
    } catch (error) {
      enqueueSnackbar("A server error occurred", {
        variant: "error",
      });
      console.error(error);
    }
  };
}

function PasswordFormDialog({
  open,
  enqueueSnackbar,
  handleClose,
}: {
  open: boolean;
  enqueueSnackbar: ProviderContext["enqueueSnackbar"];
  handleClose: () => void;
}) {
  const validate = ({ currentPassword, newPassword, confirmPassword }: UserPasswordChange) => {
    const errors = {} as UserPasswordChange;

    let validation = validatePassword(currentPassword);
    if (!validation.valid) errors.currentPassword = validation.msg;

    validation = validatePassword(newPassword);
    if (!validation.valid) errors.newPassword = validation.msg;
    else if (currentPassword === newPassword)
      errors.newPassword = "New password must be different from current password";

    validation = validatePasswordConfirm(newPassword, confirmPassword);
    if (!validation.valid) errors.confirmPassword = validation.msg;

    return errors;
  };

  const { values, handleChange, handleBlur, handleSubmit, isSubmitting, errors } = useFormik<UserPasswordChange>({
    initialValues: { currentPassword: "", newPassword: "", confirmPassword: "" },
    onSubmit: onChangePassword(enqueueSnackbar, handleClose),
    validate,
    validateOnChange: false,
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box component="form" onSubmit={handleSubmit} sx={{ minWidth: "400px" }}>
        <DialogTitle>Change password</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2} sx={{ mt: 1 }}>
            <TextField
              id={"currentPassword"}
              name={"currentPassword"}
              label={"Current Password"}
              type="password"
              required
              fullWidth
              autoComplete="current-password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.currentPassword}
              error={Boolean(errors.currentPassword)}
              helperText={errors.currentPassword}
            />
            <TextField
              id={"newPassword"}
              name={"newPassword"}
              label={"New Password"}
              type="password"
              required
              fullWidth
              autoComplete="new-password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPassword}
              error={Boolean(errors.newPassword)}
              helperText={errors.newPassword}
            />

            <TextField
              id={"confirmPassword"}
              name={"confirmPassword"}
              label={"Confirm Password"}
              type="password"
              required
              fullWidth
              autoComplete="new-password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
              error={Boolean(errors.confirmPassword)}
              helperText={errors.confirmPassword}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

function AddressBook() {
  return (
    <Card component="section" variant="outlined" sx={{ borderRadius: "8px", padding: "8px" }}>
      <CardHeader title="Address Book" />
      <CardContent>
        <Button variant="outlined" fullWidth href="account/address-book/">
          Manage Address Book
        </Button>
      </CardContent>
    </Card>
  );
}
