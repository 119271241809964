export function validatePassword(password: string) {
  let valid = true;
  let msg = "";

  if (!password) {
    valid = false;
    msg = "Enter a password";
  } else if (password.length < 8) {
    valid = false;
    msg = "Use 8 characters or more for your password";
  } else if (password.length > 100) {
    valid = false;
    msg = "Use 100 characters or fewer for your password";
  }

  return { valid, msg };
}

export function validatePasswordConfirm(password: string, confirm: string) {
  let valid = true;
  let msg = "";

  if (validatePassword(password).valid && !confirm) {
    valid = false;
    msg = "Confirm your password";
  } else if (password !== confirm) {
    valid = false;
    msg = "The passwords didn’t match. Try again.";
  }

  return { valid, msg };
}
