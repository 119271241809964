import useSingleEffect from "Hooks/useSingleEffect";
import { validSession } from "Services/api/users/users";
import { NavigateFunction, useNavigate } from "react-router-dom";

export function Home() {
  const navigate = useNavigate();

  useSingleEffect(() => void navigateToInitPage(navigate));

  return <></>;
}

async function navigateToInitPage(navigate: NavigateFunction) {
  const isValid = await validSession();
  if (isValid) navigate("../dashboard");
  else navigate("../login");
}
