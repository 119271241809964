import { Box, Button, Grid } from "@mui/material";
import { FormContainer, FormikForm, FormikSubmitButton, FormikTextField } from "@nc/neoscloud-common-react";
import logo from "Public/LockLogo.png";
import { forgotPassword, restorePassword, verifyCode } from "Services/api/auth/auth";
import { RestoreData } from "Services/api/auth/interfaces";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

type RestoreStep = "email" | "code" | "passwords";

export function Restore() {
  const navigate = useNavigate();
  const [step, setStep] = useState<RestoreStep>("email");
  const [data, setData] = useState<RestoreData>({
    email: "",
    code: "",
    password: "",
    confirmPassword: "",
  });

  return (
    <FormContainer
      title="Restore your password"
      logo={
        <Box
          src={logo}
          sx={{
            width: "60px",
            paddingBottom: "20px",
          }}
          component="img"
          alt="NeosAccount logo"
        />
      }
    >
      {step === "email" ? (
        <EmailForm
          onSubmit={(email) => {
            setStep("code");
            setData({ ...data, email });
          }}
        />
      ) : step === "code" ? (
        <CodeForm
          email={data.email}
          onSubmit={(code) => {
            setStep("passwords");
            setData({ ...data, code });
          }}
          onFail={() => setStep("email")}
          onBack={() => setStep("email")}
        />
      ) : (
        <PasswordForm
          email={data.email}
          code={data.code}
          onSubmit={() => navigate("../login")}
          onFail={() => setStep("email")}
        />
      )}
    </FormContainer>
  );
}

function EmailForm({ onSubmit }: { onSubmit: (email: string) => void }) {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string().required("Required").email("Invalid email address"),
      })}
      validateOnBlur
      onSubmit={async ({ email }, { setSubmitting }) => {
        try {
          const { status, data } = await forgotPassword(email);

          if (status === "fail") {
            enqueueSnackbar(data, {
              variant: "error",
            });
            return;
          }
          enqueueSnackbar(data, {
            variant: "success",
          });
          onSubmit(email);
        } catch (error) {
          console.error(error);
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <FormikForm width="100%" sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikTextField
              id="email"
              name="email"
              label="Enter email"
              type="email"
              required
              fullWidth
              helperText={"Enter the email address you used to register"}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikSubmitButton fullWidth variant="contained" sx={{ mb: 2 }}>
              Submit
            </FormikSubmitButton>
          </Grid>
        </Grid>
      </FormikForm>
    </Formik>
  );
}

function CodeForm({
  email,
  onSubmit,
  onFail,
  onBack,
}: {
  email: string;
  onSubmit: (code: string) => void;
  onFail: () => void;
  onBack: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Formik
      initialValues={{
        code: "",
      }}
      validationSchema={Yup.object({
        code: Yup.string().required("Required"),
      })}
      onSubmit={async ({ code }, { setSubmitting }) => {
        try {
          const { status, data } = await verifyCode(email, code);

          if (status === "fail") {
            const { errorKey, message } = data;
            enqueueSnackbar(message, {
              variant: "error",
            });
            if (errorKey !== "verificationCodeIncorrect") onFail();
            return;
          }

          enqueueSnackbar(data, {
            variant: "success",
          });
          onSubmit(code);
        } catch (error) {
          console.error(error);
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        } finally {
          setSubmitting(false);
        }
      }}
      validateOnBlur
    >
      <FormikForm width="100%" sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikTextField
              id="code"
              name="code"
              label="Enter code"
              required
              fullWidth
              helperText={`Enter the verification code we sent to ${email}. If you don't see it, check your spam folder.`}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button onClick={onBack} variant="text" sx={{ mt: 3, mb: 2 }}>
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikSubmitButton fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Continue
            </FormikSubmitButton>
          </Grid>
        </Grid>
      </FormikForm>
    </Formik>
  );
}

function PasswordForm({
  email,
  code,
  onSubmit,
  onFail,
}: {
  email: string;
  code: string;
  onSubmit: () => void;
  onFail: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .required("Enter password")
          .min(8, "Use 8 characters or more for your password")
          .max(100, "Use 100 characters or fewer for your password"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password"), null], "The passwords didn’t match. Try again.")
          .required("Confirm your password"),
      })}
      validateOnBlur
      onSubmit={async ({ password, confirmPassword }, { setSubmitting }) => {
        try {
          const { status, data } = await restorePassword({ email, code, password, confirmPassword });

          if (status === "fail") {
            const { message } = data;
            enqueueSnackbar(message, {
              variant: "error",
            });
            return onFail();
          }

          enqueueSnackbar(data, {
            variant: "success",
          });
          onSubmit();
        } catch (error) {
          console.error(error);
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <FormikForm width="100%" sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikTextField
              id="password"
              name="password"
              label="Enter new password"
              type="password"
              autoComplete="new-password"
              required
              fullWidth
              helperText="Use 8 or more characters with a mix of letters, numbers & symbols"
            />
          </Grid>
          <Grid item xs={12}>
            <FormikTextField
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm new password"
              type="password"
              autoComplete="new-password"
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikSubmitButton fullWidth variant="contained" sx={{ mb: 2 }}>
              Submit
            </FormikSubmitButton>
          </Grid>
        </Grid>
      </FormikForm>
    </Formik>
  );
}
