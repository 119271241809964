import { Box, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getService, getServiceInstances, getTenantServiceToken } from "Services/api/services/services";
import { NEOSCRM_URL, NEOSGOAL_TENANT_URL } from "Services/envVariables";
import { ServiceInstancesTable } from "Shared/ServiceInstancesTable/ServiceInstancesTable";
import { ServiceLogo } from "Shared/ServiceLogo/ServiceLogo";
import { TenantBasedServiceLinkDialog } from "Shared/TenantBasedServiceLinkDialog/TenantBasedServiceLinkDialog";
import { ProviderContext, useSnackbar } from "notistack";
import { curry } from "ramda";
import { Fragment, useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { store } from "../../../state/store";
import { useStore } from "../../../state/storeHooks";
import { initializeServicePage, loadService } from "./ServicePage.slice";

export function ServicePage() {
  const { loadedContent } = useStore(({ servicePage }) => servicePage);
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);

  useEffect(() => {
    void onLoadServicePage(enqueueSnackbar, navigate, parseInt(id as string));
  }, [id]);

  return (
    <>
      <Stack direction="column" spacing={2} sx={{ padding: "32px" }}>
        {loadedContent.match({
          none: () => <CircularProgress />,
          some: ({ service: { name, logoUrl, description }, serviceInstances }) => (
            <Fragment>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <ServiceLogo serviceName={name} logoUrl={logoUrl} />
                <Typography component="h2" variant="h6" sx={{ pl: "10px" }}>
                  {name}
                </Typography>
              </Box>

              <p>{description}</p>

              <Stack direction="row" justifyContent="flex-end">
                <Button variant="contained" onClick={() => setIsLinkDialogOpen(true)}>
                  Link an instance
                </Button>
              </Stack>

              {serviceInstances.length !== 0 && (
                <ServiceInstancesTable
                  service={name}
                  serviceInstances={serviceInstances}
                  onInstanceClicked={onServiceClicked(enqueueSnackbar)}
                  includeRemove={true}
                />
              )}

              <TenantBasedServiceLinkDialog
                open={isLinkDialogOpen}
                handleClose={() =>
                  onLinkDialogClosed(enqueueSnackbar, navigate, parseInt(id as string), setIsLinkDialogOpen)
                }
                service={name === "NeosCRM" ? "NeosCRM" : "NeosGoal"}
                logoUrl={logoUrl}
              />
            </Fragment>
          ),
        })}
      </Stack>
    </>
  );
}

const onServiceClicked = curry(async function (
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  service: string,
  serviceUser: string,
  tenant: string
) {
  const result = await getTenantServiceToken(service, serviceUser, tenant);

  if (result.status === "fail") {
    enqueueSnackbar(result.data);
    return;
  }

  if (service === "NeosCRM") window.open(`${NEOSCRM_URL}?neosAccountToken=${result.data}`, "_blank");
  else if (service === "NeosGoal")
    window.open(`${NEOSGOAL_TENANT_URL}/login?neosAccountToken=${result.data}`.replace("${tenant}", tenant), "_blank");
  else
    enqueueSnackbar(`Service with name ${service} doesn't have on click handler. Please contact support.`, {
      variant: "error",
    });
});

async function onLoadServicePage(
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  navigate: NavigateFunction,
  id: number
) {
  store.dispatch(initializeServicePage());
  const serviceResult = await getService(id);

  if (serviceResult.status === "fail") {
    enqueueSnackbar(serviceResult.data);
    navigate("../dashboard");
    return;
  }

  const instancesResult = await getServiceInstances(id);

  if (instancesResult.status === "fail") {
    enqueueSnackbar("An error ocurred loading linked services");
    navigate("../dashboard");
    return;
  }

  store.dispatch(
    loadService({
      service: serviceResult.data,
      serviceInstances: instancesResult.data,
    })
  );
}

function onLinkDialogClosed(
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  navigate: NavigateFunction,
  id: number,
  setIsLinkDialogOpen: (value: boolean) => void
) {
  void onLoadServicePage(enqueueSnackbar, navigate, id);
  setIsLinkDialogOpen(false);
}
