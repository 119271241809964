import { Decoder } from "decoders";

export function canDecode<T>(decoder: Decoder<T>, data: unknown): boolean {
  try {
    decoder.verify(data);
    return true;
  } catch {
    return false;
  }
}
