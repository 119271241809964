import { useSnackbar } from "notistack";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";

export function useHandleError(message = GENERIC_ERROR_MESSAGE) {
  const { enqueueSnackbar } = useSnackbar();

  return function <T>(error: T) {
    enqueueSnackbar(message, { variant: "error" });
    console.error(error);
  };
}
