import { boolean } from "decoders";
import { neosAccountRequest } from "../config";
import {
  DefaultUserChangeResponse,
  defaultUserChangeResponseDecoder,
  RegisterResponse,
  registerResponseDecoder,
  RegisterWithEmailGenerateCodePayload,
  RegisterWithEmailPayload,
  UserDataResponse,
  userDataResponseDecoder,
  UserNamesResponse,
  userNamesResponseDecoder,
  UserPasswordChange,
} from "./interfaces";

export async function register(
  username: string,
  firstName: string,
  lastName: string,
  password: string,
  reCaptchaToken: string
): Promise<RegisterResponse> {
  const { data } = await neosAccountRequest.post<RegisterResponse>("register/", {
    username,
    firstName,
    lastName,
    password,
    reCaptchaToken,
  });

  return registerResponseDecoder.verify(data);
}

export async function generateCode(payload: RegisterWithEmailGenerateCodePayload): Promise<RegisterResponse> {
  const { data } = await neosAccountRequest.post<RegisterResponse>("register/generate-code/", payload);

  return registerResponseDecoder.verify(data);
}

export async function registerWithEmail(payload: RegisterWithEmailPayload): Promise<RegisterResponse> {
  const { data } = await neosAccountRequest.post<RegisterResponse>("register/email/", payload);

  return registerResponseDecoder.verify(data);
}

export async function validSession(): Promise<boolean> {
  const response = await neosAccountRequest.get<boolean | string>("users/valid_session/");
  if (response.data === "true") return true;
  if (response.data === "false") return false;
  return boolean.verify(response.data);
}

export async function getUserData(): Promise<UserDataResponse> {
  const { data } = await neosAccountRequest.get<UserDataResponse>("users/me/");

  return userDataResponseDecoder.verify(data);
}

export async function updatePhoto(formData: FormData): Promise<DefaultUserChangeResponse> {
  const { data } = await neosAccountRequest.post<DefaultUserChangeResponse>("users/avatar/", formData);

  return defaultUserChangeResponseDecoder.verify(data);
}

export async function clearPhoto(): Promise<DefaultUserChangeResponse> {
  const { data } = await neosAccountRequest.delete<DefaultUserChangeResponse>("users/avatar/");

  return defaultUserChangeResponseDecoder.verify(data);
}

export async function updateNames(firstName: string, lastName: string): Promise<UserNamesResponse> {
  const { data } = await neosAccountRequest.post<UserNamesResponse>("users/update_names/", {
    firstName,
    lastName,
  });

  return userNamesResponseDecoder.verify(data);
}

export async function updatePassword(reqData: UserPasswordChange): Promise<DefaultUserChangeResponse> {
  const { data } = await neosAccountRequest.post<DefaultUserChangeResponse>("users/update_password/", reqData);

  return defaultUserChangeResponseDecoder.verify(data);
}
