import { neosAccountRequest } from "../config";
import { ErrorKeyJsendResponse, errorKeyJsendResponseDecoder } from "../interfaces";
import { PlansResponse, plansResponseDecoder } from "../neosdrive/interfaces";

export async function getPlans(): Promise<PlansResponse> {
  const { data } = await neosAccountRequest.get<PlansResponse>("subscriptions/account/neosmail/");

  return plansResponseDecoder.verify(data);
}

export async function createPlan(id: string): Promise<ErrorKeyJsendResponse> {
  const { data } = await neosAccountRequest.post<ErrorKeyJsendResponse>("subscriptions/account/neosmail/", { id });

  return errorKeyJsendResponseDecoder.verify(data);
}
