import { Either } from "@sniptt/monads/build";
import { JsendResponse, jsendResponseDecoder } from "Types/jsend";
import { eitherDecoder } from "Types/monads";
import { Decoder, array, dict, string } from "decoders";

export type FailedLoginResponse = Either<Record<string, string[]>, string>;
export const failedLoginResponseDecoder: Decoder<FailedLoginResponse> = eitherDecoder(dict(array(string)), string);

export type LoginResponse = JsendResponse<string, FailedLoginResponse>;
export const loginResponseDecoder = jsendResponseDecoder(string, failedLoginResponseDecoder);

export interface RestoreData {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
}
