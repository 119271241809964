import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { AddPaymentMethod, useBackdropContext } from "@nc/neoscloud-common-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addNewCard } from "Services/api/cards/cards";
import { ErrorKeyJsendResponse } from "Services/api/interfaces";
import { PlansResponse } from "Services/api/neosdrive/interfaces";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Query } from "Shared/Query/Query";
import { ServiceLogo } from "Shared/ServiceLogo/ServiceLogo";

interface ServiceSubscriptionProps {
  serviceName: string;
  createPlan: (id: string) => Promise<ErrorKeyJsendResponse>;
  getPlans: () => Promise<PlansResponse>;
}

export function ServiceSubscription({ serviceName, getPlans, createPlan }: ServiceSubscriptionProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [, setBackdropState] = useBackdropContext();
  const [planId, setPlanId] = useState<string>("");
  const [addCardDialog, setAddCardDialog] = useState(false);
  const confirm = useConfirm();
  const navigate = useNavigate();

  const result = useQuery({
    queryKey: [serviceName + getPlans.name],
    queryFn: async () => {
      const { data } = await getPlans();

      return data;
    },
  });

  const { mutateAsync: subscribe } = useMutation({
    mutationFn: async (id: string) => {
      setBackdropState({
        open: true,
        msg: "Subscribing...",
      });
      const { status, data } = await createPlan(id);

      if (status !== "success") {
        if (data.errorKey === "userNoPaymentMethod") {
          setPlanId(id);
          enqueueSnackbar(data.message, { variant: "warning" });
          setAddCardDialog(true);
        } else {
          enqueueSnackbar(data.message, { variant: "error" });
          console.error(data.message);
        }
        return null;
      }

      setPlanId("");
      setAddCardDialog(false);

      enqueueSnackbar("Subscribed successfully", { variant: "success" });
      navigate("/");

      return null;
    },
    onError: () => enqueueSnackbar("An error happened while trying to create the subscription", { variant: "error" }),
    onSettled: () => {
      setBackdropState({
        open: false,
        msg: "",
      });
    },
  });

  return (
    <Query
      result={result}
      onSuccess={({ name, description, logoUrl, plans }) => (
        <Stack spacing={3}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <ServiceLogo serviceName={name} logoUrl={logoUrl} />
            <Typography component="h2" variant="h6" sx={{ pl: "10px" }}>
              {name}
            </Typography>
          </Box>

          <Typography sx={{ fontSize: "1.1rem" }}>{description}</Typography>

          <Typography component="h3" variant="h5">
            Choose a plan
          </Typography>
          <Stack direction="row" flexWrap="wrap" spacing={3}>
            {plans.map(({ id, name, capacity, cost, isShared }) => {
              return (
                <Card
                  sx={{
                    width: "256px",
                  }}
                  key={id}
                  elevation={2}
                >
                  <Stack alignItems="center" sx={{ p: "0px 16px", height: "100%" }} justifyContent="space-between">
                    <Typography
                      sx={{
                        mt: "16px",
                        fontSize: "22px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      {name}
                    </Typography>
                    {isShared ? (
                      <Typography
                        sx={{
                          mt: "4px",
                          color: "rgb(95,99,104)",
                          fontSize: ".875rem",
                          fontWeight: "400",
                        }}
                      >
                        {serviceName.toLowerCase() === "neosdrive" ? "(Includes Neosmail)" : "(Includes Neosdrive)"}
                      </Typography>
                    ) : (
                      <Box sx={{ height: "25px" }} />
                    )}
                    <Typography
                      sx={{
                        mt: "4px",
                        fontSize: "32px",
                        fontWeight: "400",
                      }}
                    >
                      {capacity}
                    </Typography>
                    <Stack alignItems="center">
                      <Typography
                        sx={{
                          mt: "4px",
                          mb: "4px",
                          fontSize: "22px",
                          fontWeight: "400",
                        }}
                      >
                        {cost}
                      </Typography>
                      <Typography
                        sx={{
                          mt: "4px",
                          color: "rgb(95,99,104)",
                          fontSize: ".875rem",
                          fontWeight: "400",
                        }}
                      >
                        Billed monthly
                      </Typography>
                    </Stack>
                    <Button
                      variant="contained"
                      sx={{ m: "24px 0" }}
                      onClick={() => {
                        void confirm({
                          title: `${serviceName} subscription`,
                          description: `You are subscribing to the ${name} plan.`,
                          confirmationText: "Confirm",
                        }).then(() => void subscribe(id));
                      }}
                    >
                      Subscribe
                    </Button>
                  </Stack>
                </Card>
              );
            })}
          </Stack>
          <AddPaymentDialog
            open={addCardDialog}
            onClose={() => setAddCardDialog(false)}
            callback={() => subscribe(planId)}
          />
        </Stack>
      )}
    />
  );
}

interface AddPaymentDialogState {
  open: boolean;
  onClose: () => void;
  callback: (() => Promise<void>) | (() => void);
}

function AddPaymentDialog({ open, onClose, callback }: AddPaymentDialogState) {
  const { enqueueSnackbar } = useSnackbar();
  const [, setBackdropState] = useBackdropContext();

  return (
    <AddPaymentMethod
      isOpen={open}
      onClose={onClose}
      onAddPaymentMethod={async (token: string) => {
        await addNewCard(token);

        enqueueSnackbar("Payment method added successfully", {
          variant: "success",
        });
        await callback();
      }}
      onCreating={() => setBackdropState({ open: true, msg: "Creating payment method..." })}
      onError={(error) => {
        setBackdropState({ open: false, msg: "" });
        enqueueSnackbar(error, {
          variant: "error",
        });
      }}
      onSuccess={() => setBackdropState({ open: false, msg: "" })}
    />
  );
}
