import { JsendResponse, jsendResponseDecoder, jsendSuccessDecoder } from "Types/jsend";
import { Decoder, DecoderType, array, boolean, constant, dict, either, number, object, string } from "decoders";

export interface TransactionStatus {
  code: number;
  description: string;
  type: string;
}

export const transactionStatusDecoder: Decoder<TransactionStatus> = object({
  code: number,
  description: string,
  type: string,
});

export const serviceObject = {
  id: number,
  name: string,
  description: string,
  category: either(constant("SERVICE"), constant("APPLICATION")),
  url: string,
  logoUrl: string,
  hasTenant: boolean,
};

export const serviceDecoder = object(serviceObject);

export type Service = DecoderType<typeof serviceDecoder>;

export const servicesResponseDecoder = jsendSuccessDecoder(array(serviceDecoder));

export type ServiceResponse = DecoderType<typeof servicesResponseDecoder>;

export interface ServiceInstance {
  tenant: string;
  service: number;
  serviceUser: string;
}

export const serviceInstanceDecoder: Decoder<ServiceInstance> = object({
  tenant: string,
  service: number,
  serviceUser: string,
});

export interface TenantBasedLinkData {
  username: string;
  password: string;
  tenant: string;
  service: "NeosCRM" | "NeosGoal";
}

export type LinkNeosCRMResponse = JsendResponse<ServiceInstance, Record<string, string[]> | string | TransactionStatus>;

export const linkNeoscrmResponseDecoder: Decoder<LinkNeosCRMResponse> = jsendResponseDecoder(
  serviceInstanceDecoder,
  either(dict(array(string)), string, transactionStatusDecoder)
);
