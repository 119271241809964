import { useState } from "react";
import useSingleEffect from "Hooks/useSingleEffect";
import { State, store } from "./store";

export function useStoreWithInitializer<T>(getter: (state: State) => T, initializer: () => unknown) {
  const [state, setState] = useState(getter(store.getState()));
  useSingleEffect(() => {
    const unsubscribe = store.subscribe(() => setState(getter(store.getState())));
    initializer();
    return unsubscribe;
  });
  return state;
}

export function useStore<T>(getter: (state: State) => T) {
  return useStoreWithInitializer(getter, () => {});
}
