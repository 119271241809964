import { JsendResponse, jsendResponseDecoder } from "Types/jsend";
import { string } from "decoders";
import { neosAccountRequest } from "../config";
import {
  AddPaymentMethodsResponse,
  CreatePaymentMethodResponse,
  PaymentMethodsResponse,
  addPaymentMethodsResponseDecoder,
  paymentMethodsResponseDecoder,
} from "./interfaces";

export async function getPaymentMethods(): Promise<PaymentMethodsResponse> {
  const { data } = await neosAccountRequest.get<PaymentMethodsResponse>(`cards/`);
  return paymentMethodsResponseDecoder.verify(data);
}

export async function addNewCard(tokenId: string): Promise<AddPaymentMethodsResponse> {
  const { data } = await neosAccountRequest.post<CreatePaymentMethodResponse>(`cards/`, {
    source: tokenId,
  });
  return addPaymentMethodsResponseDecoder.verify(data);
}

export async function deletePaymentMethod(paymentMethodId: string): Promise<JsendResponse<string, string>> {
  const { data } = await neosAccountRequest.delete<JsendResponse<string, string>>(`cards/${paymentMethodId}/`);

  return jsendResponseDecoder(string, string).verify(data);
}

export async function setDefaultPaymentMethod(paymentMethodId: string): Promise<JsendResponse<string, string>> {
  const { data } = await neosAccountRequest.post<JsendResponse<string, string>>(
    `cards/${paymentMethodId}/set_default/`
  );

  return jsendResponseDecoder(string, string).verify(data);
}
