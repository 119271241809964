import { neosAccountRequest, setCsrfToken } from "../config";
import {
  ErrorKeyJsendResponse,
  StringJsendResponse,
  errorKeyJsendResponseDecoder,
  stringJsendResponseDecoder,
} from "../interfaces";
import { LoginResponse, RestoreData, loginResponseDecoder } from "./interfaces";

export async function login(username: string, password: string, keepSignedIn: boolean): Promise<LoginResponse> {
  const { data } = await neosAccountRequest.post<LoginResponse>("auth/login/", {
    username,
    password,
    keepSignedIn,
  });
  setCsrfToken();
  return loginResponseDecoder.verify(data);
}

export async function logout(): Promise<void> {
  await neosAccountRequest.get("auth/logout/");
}

export async function forgotPassword(email: string): Promise<StringJsendResponse> {
  const { data } = await neosAccountRequest.post<LoginResponse>("auth/forgot-password/", { email });
  return stringJsendResponseDecoder.verify(data);
}

export async function verifyCode(email: string, code: string): Promise<ErrorKeyJsendResponse> {
  const { data } = await neosAccountRequest.post<LoginResponse>("auth/verify-code/", { email, code });
  return errorKeyJsendResponseDecoder.verify(data);
}

export async function restorePassword(payload: RestoreData): Promise<ErrorKeyJsendResponse> {
  const { data } = await neosAccountRequest.post<LoginResponse>("auth/restore-password/", payload);
  return errorKeyJsendResponseDecoder.verify(data);
}
