import {
  Box,
  Button,
  Container,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { MoneyFormat, useBackdropContext } from "@nc/neoscloud-common-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useHandleError } from "Hooks/useHandleError";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { ListSubscription } from "Services/api/subscriptions/interfaces";
import { cancelSubscription, getSubscriptions, reactivateSubscription } from "Services/api/subscriptions/subscriptions";
import { Query } from "Shared/Query/Query";

export function Subscriptions() {
  return (
    <Container component="section" sx={{ marginTop: 5 }}>
      <SubscriptionList />
    </Container>
  );
}

function SubscriptionList() {
  const errorMsg = "An error ocurred loading subscriptions";
  const handleError = useHandleError(errorMsg);
  const [subscriptions, setSubscriptions] = useState<ListSubscription[]>([]);

  const result = useQuery({
    queryKey: [getSubscriptions.name],
    queryFn: async () => {
      const { data } = await getSubscriptions();
      setSubscriptions(data);
      return data;
    },
    cacheTime: 0,
  });

  return (
    <Query
      result={result}
      OnLoading={() => <Skeleton variant="rectangular" height={200} />}
      onError={() => <div>{`${errorMsg}...`}</div>}
      onSuccess={() => <SubscriptionTable subscriptions={subscriptions} setSubscriptions={setSubscriptions} />}
      onErrorCallback={(error) => handleError(error)}
    />
  );
}

function SubscriptionTable({
  subscriptions,
  setSubscriptions,
}: {
  subscriptions: ListSubscription[];
  setSubscriptions: React.Dispatch<React.SetStateAction<ListSubscription[]>>;
}) {
  return (
    <Fragment>
      {subscriptions.length === 0 && <h4>You don&apos;t have subscriptions.</h4>}
      {subscriptions.length > 0 && (
        <TableContainer>
          <Table sx={{ minWidth: 550, textAlign: "center" }} aria-label="Subscriptions">
            <TableBody>
              {subscriptions.map((subscription, index) => (
                <Row
                  key={subscription.id}
                  subscriptions={subscriptions}
                  setSubscriptions={setSubscriptions}
                  index={index}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Fragment>
  );
}

function Row({
  subscriptions,
  setSubscriptions,
  index,
}: {
  subscriptions: ListSubscription[];
  setSubscriptions: React.Dispatch<React.SetStateAction<ListSubscription[]>>;
  index: number;
}) {
  const subscription = subscriptions[index];
  const {
    isCloudSubscription,
    cancelAtPeriodEnd,
    currentPeriodEnd,
    service,
    amount,
    description,
    status,
    statusLabel,
  } = subscription;
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [, setBackdropState] = useBackdropContext();

  const { mutateAsync: cancelSub } = useMutation({
    mutationFn: async () => {
      setBackdropState({
        open: true,
        msg: "Cancelling subscription...",
      });
      const subscription = subscriptions[index];
      const { data: canceled } = await cancelSubscription(subscription.id);
      const newSubs = [...subscriptions];
      newSubs[index] = canceled;
      setSubscriptions(newSubs);
    },
    onError: (error) => {
      enqueueSnackbar("An error occured while canceling the subscription", {
        variant: "error",
      });
      console.error(error);
    },
    onSettled: () => {
      setBackdropState({
        open: false,
        msg: "",
      });
    },
  });

  const onCancel = () =>
    void confirm({
      title: "Cancel Subscription",
      description: "Are you sure you want to cancel this subscription?",
    }).then(() => cancelSub());

  const { mutateAsync: reactivateSub } = useMutation({
    mutationFn: async () => {
      setBackdropState({
        open: true,
        msg: "Reactivating subscription...",
      });
      const subscription = subscriptions[index];
      const { data } = await reactivateSubscription(subscription.id);

      const newSubs = [...subscriptions];
      newSubs[index] = data;
      setSubscriptions(newSubs);
    },
    onError: (error) => {
      enqueueSnackbar("An error occured while canceling the subscription", {
        variant: "error",
      });
      console.error(error);
    },
    onSettled: () => {
      setBackdropState({
        open: false,
        msg: "",
      });
    },
  });

  const onReactivate = () =>
    void confirm({
      title: "Reactivate Subscription",
      description: "Are you sure you want to reactivate this subscription?",
    }).then(() => reactivateSub());

  const InfoCell = () => {
    if (isCloudSubscription)
      return (
        <TableCell>
          Month-to-date usage: <MoneyFormat amount={amount} /> to be charged {cancelAtPeriodEnd ? "and cancelled" : ""}{" "}
          on {currentPeriodEnd}
        </TableCell>
      );
    if (cancelAtPeriodEnd) return <TableCell>Will be canceled on {currentPeriodEnd}</TableCell>;
    return (
      <TableCell>
        Next payment: <MoneyFormat amount={amount} /> on {currentPeriodEnd}
      </TableCell>
    );
  };

  return (
    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell>
        <Stack>
          <Typography sx={{ fontSize: "13px" }} gutterBottom>
            <Box component={"span"} sx={{ fontWeight: 500 }}>
              {service}{" "}
            </Box>
            {(status !== "active" || cancelAtPeriodEnd) && (
              <Box
                component={"span"}
                sx={{
                  backgroundColor: "rgb(235, 238, 241)",
                  p: "1px 6px",
                  ...(cancelAtPeriodEnd ? { color: "darkred" } : { color: "rgb(84, 89, 105)" }),
                }}
              >
                ({statusLabel})
              </Box>
            )}
          </Typography>
          <Typography variant="caption" sx={{ color: "#8d8d8d" }}>
            {description}
          </Typography>
        </Stack>
      </TableCell>

      <InfoCell />

      <TableCell align="center" scope="row">
        {cancelAtPeriodEnd ? (
          <RowAction label="Reactivate" subscription={subscription} onClick={onReactivate} />
        ) : (
          <RowAction label="Cancel" subscription={subscription} onClick={onCancel} />
        )}
      </TableCell>
    </TableRow>
  );
}

interface RowActionProps {
  label: string;
  subscription: ListSubscription;
  onClick: () => undefined | void;
}

function RowAction(props: RowActionProps) {
  const { label, subscription, onClick } = props;

  if (subscription.status === "canceled" || subscription.isCloudSubscription) return <></>;

  return (
    <Button data-testid={`${subscription.id}-${label.toLowerCase()}`} onClick={onClick}>
      {label}
    </Button>
  );
}
