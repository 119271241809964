import axios from "axios";
import Cookies from "js-cookie";
import { NEOSACCOUNT_API_URL } from "../envVariables";

export const neosAccountRequest = axios.create({ baseURL: NEOSACCOUNT_API_URL, withCredentials: true });
setCsrfToken();

export function setCsrfToken() {
  const csrftoken = Cookies.get("csrftoken") || "";
  neosAccountRequest.defaults.headers.post["X-CSRFToken"] = csrftoken;
  neosAccountRequest.defaults.headers.put["X-CSRFToken"] = csrftoken;
  neosAccountRequest.defaults.headers.delete["X-CSRFToken"] = csrftoken;
}
