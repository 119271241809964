import { Button, Grid, Typography } from "@mui/material";
import { FormikForm, FormikSubmitButton, FormikTextField } from "@nc/neoscloud-common-react";
import { login } from "Services/api/auth/auth";
import { registerWithEmail } from "Services/api/users/users";
import { Formik } from "formik";
import { ProviderContext, useSnackbar } from "notistack";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { RegistrationRequestData } from "./Registration";
import { handleFailedRegistration } from "./handleFailedRegistration";

export function EmailConfirmForm({
  requestData,
  setConfirmEmail,
}: {
  requestData: RegistrationRequestData;
  setConfirmEmail: Dispatch<SetStateAction<boolean>>;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});

  return (
    <>
      <Typography component="h2" variant="h6">
        Verify your email address
      </Typography>
      <Formik
        initialValues={{
          code: "",
        }}
        validationSchema={Yup.object({
          code: Yup.string().required("Required"),
        })}
        onSubmit={async ({ code }, { setSubmitting }) => {
          try {
            await register(requestData, code, enqueueSnackbar, navigate, searchParams);
          } catch (error) {
            console.error(error);
            enqueueSnackbar("Something went wrong", {
              variant: "error",
            });
          } finally {
            setSubmitting(false);
          }
        }}
        validateOnBlur
      >
        <FormikForm width="100%" sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormikTextField
                id="code"
                name="code"
                label="Enter code"
                required
                fullWidth
                helperText={`Enter the verification code we sent to ${
                  requestData?.email || ""
                }. If you don't see it, check your spam folder.`}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button onClick={() => setConfirmEmail(false)} variant="text" sx={{ mt: 3, mb: 2 }}>
                Back
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormikSubmitButton fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Verify & Sign Up
              </FormikSubmitButton>
            </Grid>
          </Grid>
        </FormikForm>
      </Formik>
    </>
  );
}

async function register(
  data: RegistrationRequestData,
  code: string,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  navigate: NavigateFunction,
  searchParams: URLSearchParams
) {
  const { email, firstName, lastName, password } = data;

  if (!email) {
    enqueueSnackbar("Email is required", {
      variant: "error",
    });
    return;
  }

  let result = await registerWithEmail({
    email,
    firstName,
    lastName,
    password,
    code,
  });

  if (result.status === "fail") {
    handleFailedRegistration(result, enqueueSnackbar);
    return;
  }

  enqueueSnackbar(result.data, {
    variant: "success",
  });

  const origin = searchParams.get("origin");
  if (origin != null && origin == "app") {
    navigate("../login?status=completed");
  } else {
    result = await login(email, password, false);

    if (result.status === "fail") {
      enqueueSnackbar("Auto login failed, try log in yourself and if it problem persist contact support", {
        variant: "error",
      });
      navigate("../login");
    }

    navigate("../dashboard");
  }
}
