import { AddCircleOutline } from "@mui/icons-material";
import { Backdrop, Button, CircularProgress, Container, Skeleton, Typography } from "@mui/material";
import { AddPaymentMethod, BackdropState, setStripeKey } from "@nc/neoscloud-common-react";
import { useQuery } from "@tanstack/react-query";
import { addNewCard, deletePaymentMethod, getPaymentMethods, setDefaultPaymentMethod } from "Services/api/cards/cards";
import { PaymentMethod } from "Services/api/cards/interfaces";
import { STRIPE_KEY } from "Services/envVariables";
import { Query } from "Shared/Query/Query";
import { ProviderContext, useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { ListPaymentMethod } from "./ListPaymentMethod";

void setStripeKey(STRIPE_KEY);

export function Payment() {
  return (
    <>
      <Container component="header" maxWidth="md" sx={{ marginTop: 2 }}>
        <Typography component="h1" variant="h5" gutterBottom sx={{ pb: "10px" }}>
          Payment Methods
        </Typography>
      </Container>
      <Container component="section" maxWidth="md" sx={{ marginTop: 2 }}>
        <PaymentMethods />
      </Container>
    </>
  );
}

function PaymentMethods() {
  const [openCardDialog, setOpenCardDialog] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [backdropState, setBackdropState] = useState<BackdropState>({ open: false, msg: "" });

  const result = useQuery({
    queryKey: [getPaymentMethods.name],
    queryFn: async () => {
      const { data } = await getPaymentMethods();
      setPaymentMethods(data);
      return data;
    },
    cacheTime: 0,
  });

  return (
    <Query
      result={result}
      OnLoading={() => (
        <>
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="text" width={190} />
        </>
      )}
      onError={() => <div>An error ocurred loading payment methods...</div>}
      onSuccess={() => (
        <>
          {openCardDialog && (
            <AddPaymentMethod
              isOpen={openCardDialog}
              onClose={() => setOpenCardDialog(false)}
              onAddPaymentMethod={(token) =>
                void onAddPaymentMethod(token, enqueueSnackbar, paymentMethods, setPaymentMethods, setOpenCardDialog)
              }
            />
          )}
          {paymentMethods.length === 0 && <h4>You don&apos;t have payment methods.</h4>}
          {paymentMethods.length > 0 && (
            <ListPaymentMethod
              paymentMethods={paymentMethods}
              onDelete={(id) =>
                void onRemoveItem(id, paymentMethods, setPaymentMethods, enqueueSnackbar, setBackdropState)
              }
              onDefault={(id) =>
                void onSetDefault(id, paymentMethods, setPaymentMethods, enqueueSnackbar, setBackdropState)
              }
            />
          )}
          <section>
            <h6>
              <Button startIcon={<AddCircleOutline />} className="pointer" onClick={() => setOpenCardDialog(true)}>
                Add payment method
              </Button>
            </h6>
          </section>
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }} open={backdropState.open}>
            <CircularProgress color="inherit" />
            <div style={{ paddingLeft: "20px" }}>{backdropState.msg}</div>
          </Backdrop>
        </>
      )}
    />
  );
}

async function onAddPaymentMethod(
  token: string,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  paymentMethods: PaymentMethod[],
  setPaymentMethods: Dispatch<SetStateAction<PaymentMethod[]>>,
  setOpenCardDialog: Dispatch<SetStateAction<boolean>>
) {
  const { status, data } = await addNewCard(token);

  if (status === "success") {
    enqueueSnackbar("Payment method added successfully", {
      variant: "success",
    });
    setPaymentMethods([...[data], ...paymentMethods]);
    setOpenCardDialog(false);
  } else if (data.errorKey === "stripeCardError")
    enqueueSnackbar(data.message, {
      variant: "error",
    });
  else {
    enqueueSnackbar("An error occured while adding the payment method", {
      variant: "error",
    });
    console.error(data);
  }
}

async function onRemoveItem(
  paymentMethodId: string,
  paymentMethods: PaymentMethod[],
  setPaymentMethods: Dispatch<SetStateAction<PaymentMethod[]>>,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  setBackdropState: Dispatch<SetStateAction<BackdropState>>
) {
  const showError = () =>
    enqueueSnackbar("An error occured while deleting the payment method", {
      variant: "error",
    });
  try {
    setBackdropState({ open: true, msg: "Deleting payment method..." });
    const response = await deletePaymentMethod(paymentMethodId);

    if (response.status === "success") {
      enqueueSnackbar("Payment method deleted successfully", {
        variant: "success",
      });
      setBackdropState({ open: false, msg: "" });
      setPaymentMethods(paymentMethods.filter((e) => e.id !== paymentMethodId));
    } else {
      showError();
      console.error(response.data);
    }
  } catch (e) {
    setBackdropState({ open: false, msg: "" });
    showError();
    console.error(e);
  }
}

async function onSetDefault(
  paymentMethodId: string,
  paymentMethods: PaymentMethod[],
  setPaymentMethods: Dispatch<SetStateAction<PaymentMethod[]>>,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  setBackdropState: Dispatch<SetStateAction<BackdropState>>
) {
  const showError = () =>
    enqueueSnackbar("An error occured while updating the payment method", {
      variant: "error",
    });
  try {
    setBackdropState({ open: true, msg: "Updating payment method..." });
    const { status, data } = await setDefaultPaymentMethod(paymentMethodId);

    if (status === "success") {
      enqueueSnackbar(data, {
        variant: "success",
      });
      setBackdropState({ open: false, msg: "" });
      setPaymentMethods(
        paymentMethods.map((paymentMethod) => ({ ...paymentMethod, default: paymentMethod.id === paymentMethodId }))
      );
    } else {
      showError();
      console.error(data);
    }
  } catch (e) {
    setBackdropState({ open: false, msg: "" });
    showError();
    console.error(e);
  }
}
