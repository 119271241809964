import { UseQueryResult } from "@tanstack/react-query";
import { GENERIC_ERROR_MESSAGE } from "Utils/constants";

type QueryProps<T, E> = {
  result: UseQueryResult<T, E>;
  onSuccess: (value: T) => JSX.Element | null;
  OnLoading?: () => JSX.Element | null;
  onError?: (error: E) => JSX.Element | null;
  onErrorCallback?: (error: E) => void;
};

export function Query<T, E>({
  result,
  onSuccess,
  OnLoading = () => <div>Loading...</div>,
  onError = () => <div>{GENERIC_ERROR_MESSAGE}</div>,
  onErrorCallback = () => {},
}: QueryProps<T, E>) {
  const { isError, isLoading, isFetching, data, error } = result;

  if (isError) {
    onErrorCallback(error);
    return onError(error);
  }
  if (isLoading || isFetching) return OnLoading();
  return onSuccess(data);
}
