import { neosAccountRequest } from "../config";
import {
  ListSubscriptionResponse,
  ListSubscriptionsResponse,
  listSubscriptionResponseDecoder,
  listSubscriptionsResponseDecoder,
} from "./interfaces";

export async function getSubscriptions(): Promise<ListSubscriptionsResponse> {
  const { data } = await neosAccountRequest.get<ListSubscriptionsResponse>(`subscriptions/`);
  return listSubscriptionsResponseDecoder.verify(data);
}

export async function cancelSubscription(subscriptionId: string): Promise<ListSubscriptionResponse> {
  const { data } = await neosAccountRequest.delete<ListSubscriptionResponse>(
    `subscriptions/account/${subscriptionId}/`
  );

  return listSubscriptionResponseDecoder.verify(data);
}

export async function reactivateSubscription(subscriptionId: string): Promise<ListSubscriptionResponse> {
  const { data } = await neosAccountRequest.post<ListSubscriptionResponse>(
    `subscriptions/account/${subscriptionId}/reactivate/`
  );

  return listSubscriptionResponseDecoder.verify(data);
}
