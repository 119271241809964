import { Button, FormControlLabel, Grid, InputAdornment, Link } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { FormikForm, FormikSubmitButton, FormikTextField } from "@nc/neoscloud-common-react";
import useSingleEffect from "Hooks/useSingleEffect";
import { login } from "Services/api/auth/auth";
import { generateCode, register } from "Services/api/users/users";
import { Formik, useField } from "formik";
import { ProviderContext, useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useState } from "react";
import { NavigateFunction, Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useReCAPTCHAContext } from "../../App/RecaptchaContext";
import { RegistrationRequestData } from "./Registration";
import { handleFailedRegistration } from "./handleFailedRegistration";

export const formInputConfigs = {
  firstName: {
    id: "firstName",
    name: "firstName",
    label: "First Name",
  },
  email: {
    id: "email",
    name: "email",
    label: "Email",
    helperText: "You'll need to confirm that this email belongs to you.",
  },
  lastName: {
    id: "lastName",
    name: "lastName",
    label: "Last Name",
  },
  username: {
    id: "username",
    name: "username",
    label: "Username",
    helperText: "You can use letters, numbers & periods",
  },
  password: {
    id: "password",
    name: "password",
    label: "Password",
    helperText: "Use 8 or more characters with a mix of letters, numbers & symbols",
  },
  confirm: {
    id: "confirm",
    name: "confirm",
    label: "Confirm",
  },
};

const schema = Yup.object({
  firstName: Yup.string().required("Enter first name"),
  lastName: Yup.string().required("Enter last name"),
  registerWithOwnEmail: Yup.boolean(),
  username: Yup.string()
    .when("registerWithOwnEmail", {
      is: false,
      then: Yup.string()
        .required("Enter username")
        .min(6, "Username must be at least 6 characters")
        .max(30, "Username must be at most 30 characters"),
    })
    .matches(/^[a-zA-Z0-9.]*$/, "Sorry, only letters (a-z), numbers (0-9), and periods (.) are allowed."),
  email: Yup.string().when("registerWithOwnEmail", {
    is: true,
    then: Yup.string().email("Enter valid email address").required("Enter an email address"),
  }),
  password: Yup.string()
    .required("Enter password")
    .min(8, "Use 8 characters or more for your password")
    .max(100, "Use 100 characters or fewer for your password"),
  confirm: Yup.string()
    .oneOf([Yup.ref("password"), null], "The passwords didn’t match. Try again.")
    .required("Confirm your password"),
});

interface FormProps {
  requestData: RegistrationRequestData | undefined;
  setRequestData: Dispatch<SetStateAction<RegistrationRequestData | undefined>>;
  setConfirmEmail: Dispatch<SetStateAction<boolean>>;
}

export function RegistrationForm(props: FormProps) {
  const { requestData, setRequestData, setConfirmEmail } = props;
  const recaptchaRef = useReCAPTCHAContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});

  const initialValues = requestData
    ? {
        ...requestData,
        registerWithOwnEmail: true,
        password: "",
        confirm: "",
      }
    : {
        firstName: "",
        lastName: "",
        registerWithOwnEmail: false,
        username: "",
        email: "",
        password: "",
        confirm: "",
      };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (data, { setSubmitting }) => {
        const { registerWithOwnEmail } = data;
        let reCaptchaToken: string | null = "";
        if (recaptchaRef && recaptchaRef.current) {
          reCaptchaToken = await recaptchaRef.current.executeAsync();
          recaptchaRef.current.reset();
        }

        if (!reCaptchaToken) {
          enqueueSnackbar("Problem getting reCAPTCHA token", {
            variant: "error",
          });
          setSubmitting(false);
          return;
        }
        try {
          if (!registerWithOwnEmail)
            return await registerWithUsername(
              data,
              reCaptchaToken,
              enqueueSnackbar,
              setSubmitting,
              navigate,
              searchParams
            );

          await registerWithEmail(
            data,
            reCaptchaToken,
            enqueueSnackbar,
            setSubmitting,
            setConfirmEmail,
            setRequestData
          );
        } catch (err) {
          console.error(err);
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        } finally {
          setSubmitting(false);
        }
      }}
      validateOnBlur
    >
      <Form {...props} />
    </Formik>
  );
}

function Form({ requestData, setRequestData }: FormProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [{ value: registerWithOwnEmail }, , { setValue: setRegisterWithOwnEmail }] =
    useField<boolean>("registerWithOwnEmail");
  const [, , { setValue: setUsername }] = useField<string>("username");
  const [, , { setValue: setEmail }] = useField<string>("email");

  useSingleEffect(() => {
    if (requestData) setRegisterWithOwnEmail(true);
  });
  return (
    <FormikForm width="100%" sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormikTextField
            id={formInputConfigs.firstName.id}
            name={formInputConfigs.firstName.name}
            label={formInputConfigs.firstName.label}
            required
            fullWidth
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikTextField
            id={formInputConfigs.lastName.id}
            name={formInputConfigs.lastName.name}
            label={formInputConfigs.lastName.label}
            required
            fullWidth
            autoComplete="family-name"
          />
        </Grid>
        <Grid item xs={12}>
          {registerWithOwnEmail ? (
            <FormikTextField
              id={formInputConfigs.email.id}
              name={formInputConfigs.email.name}
              label={formInputConfigs.email.label}
              required
              fullWidth
              type="email"
              autoComplete="email"
            />
          ) : (
            <FormikTextField
              id={formInputConfigs.username.id}
              name={formInputConfigs.username.name}
              label={formInputConfigs.username.label}
              required
              fullWidth
              autoComplete="username"
              InputProps={{
                endAdornment: <InputAdornment position="end">@neosmail.com</InputAdornment>,
              }}
              helperText="You can use letters, numbers & periods"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="text"
            onClick={() => {
              const newRegisterWithOwnEmail = !registerWithOwnEmail;
              setRegisterWithOwnEmail(newRegisterWithOwnEmail);
              if (newRegisterWithOwnEmail) {
                setUsername("");
                setRequestData(undefined);
              } else setEmail("");
            }}
          >
            {registerWithOwnEmail ? "Create a new Neosmail address instead" : "Use my own email instead"}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikTextField
            id={formInputConfigs.password.id}
            name={formInputConfigs.password.name}
            label={formInputConfigs.password.label}
            required
            fullWidth
            autoComplete="new-password"
            type={showPassword ? "text" : "password"}
            helperText="Use 8 or more characters with a mix of letters, numbers & symbols"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikTextField
            id={formInputConfigs.confirm.id}
            name={formInputConfigs.confirm.name}
            label={formInputConfigs.confirm.label}
            required
            fullWidth
            autoComplete="new-password"
            type={showPassword ? "text" : "password"}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="primary" onChange={() => setShowPassword(!showPassword)} />}
            label="Show password"
          />
        </Grid>
      </Grid>
      <FormikSubmitButton fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        {registerWithOwnEmail ? "Next" : "Sign Up"}
      </FormikSubmitButton>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Link component={RouterLink} to="/login" variant="body2">
            Already have an account? Sign in
          </Link>
        </Grid>
      </Grid>
    </FormikForm>
  );
}

async function registerWithEmail(
  data: RegistrationRequestData,
  reCaptchaToken: string,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  setIsSubmitting: (isSubmitting: boolean) => void,
  setConfirmEmail: FormProps["setConfirmEmail"],
  setRequestData: FormProps["setRequestData"]
) {
  setIsSubmitting(true);
  const { email, firstName, lastName, password } = data;

  if (!email) {
    enqueueSnackbar("Email is required", {
      variant: "error",
    });
    setIsSubmitting(false);
    return;
  }

  const result = await generateCode({
    email,
    firstName,
    lastName,
    password,
    reCaptchaToken,
  });

  if (result.status === "fail") {
    handleFailedRegistration(result, enqueueSnackbar);
    setIsSubmitting(false);
    return;
  }

  enqueueSnackbar(result.data, {
    variant: "success",
  });
  setRequestData(data);
  setConfirmEmail(true);
  setIsSubmitting(false);
}

async function registerWithUsername(
  data: RegistrationRequestData,
  reCaptchaToken: string,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  setIsSubmitting: (isSubmitting: boolean) => void,
  navigate: NavigateFunction,
  searchParams: URLSearchParams
) {
  setIsSubmitting(true);
  const { username, firstName, lastName, password } = data;
  if (!username) {
    enqueueSnackbar("Username is required", {
      variant: "error",
    });
    setIsSubmitting(false);
    return;
  }

  let result = await register(username, firstName, lastName, password, reCaptchaToken);

  if (result.status === "fail") {
    handleFailedRegistration(result, enqueueSnackbar);
    setIsSubmitting(false);
    return;
  }

  enqueueSnackbar(result.data, {
    variant: "success",
  });

  const origin = searchParams.get("origin");
  if (origin != null && origin == "app") {
    navigate("../login?status=completed");
  } else {
    result = await login(username, password, false);

    if (result.status === "fail") {
      enqueueSnackbar("Auto login failed, try log in yourself and if it problem persist contact support", {
        variant: "error",
      });
      navigate("../login");
    }

    navigate("../dashboard");
  }
}
