import { Avatar, SxProps, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import CloudIcon from "@mui/icons-material/Cloud";

export function ServiceLogo({
  serviceName,
  logoUrl,
  sx = {},
}: {
  serviceName: string;
  logoUrl: string;
  sx?: SxProps<Theme>;
}) {
  return logoUrl ? (
    <Avatar
      sx={{ borderRadius: "15%", ...sx }}
      alt={`${serviceName} Logo`}
      src={logoUrl}
      imgProps={{ style: { height: "initial" } }}
    ></Avatar>
  ) : (
    <Avatar sx={{ borderRadius: "15%", bgcolor: grey[900], ...sx }} alt={`${serviceName} Logo`}>
      <CloudIcon />
    </Avatar>
  );
}
