import { Button, Grid, MenuItem, Typography } from "@mui/material";
import {
  FormikCheckbox,
  FormikForm,
  FormikSelect,
  FormikSubmitButton,
  FormikTextField,
} from "@nc/neoscloud-common-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAddress, getAddresses, updateAddress } from "Services/api/addresses/addresses";
import { Address } from "Services/api/addresses/interfaces";
import { Formik, useField } from "formik";
import { useSnackbar } from "notistack";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";

export const contactFormFieldsInitialValues: Address = {
  id: null,
  name: "",
  first_name: "",
  last_name: "",
  is_default: false,
  company_name: "",
  job_title: "",
  is_company: false,
  address1: "",
  address2: "",
  city: "",
  state_province: "",
  zip_postal_code: "",
  country: "US",
  area_code: "+1",
  phone: "",
  phone_ext: "",
  has_phone_ext: false,
  email: "",
};

interface AddressFormProps {
  heading: string;
  initialValues: Address;
  edit?: boolean;
}

export function AddressForm({ heading, initialValues, edit = false }: AddressFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: onSave } = useMutation(edit ? updateAddress : createAddress, {
    onSuccess: async ({ status, data }) => {
      if (status === "fail") {
        enqueueSnackbar(data, { variant: "error" });
        return;
      }

      await queryClient.invalidateQueries([getAddresses.name]);

      enqueueSnackbar(`Address ${edit ? "edited" : "created"} successfully`, { variant: "success" });
      navigate(`/account/address-book/`);
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar(`Error ${edit ? "editing" : "creating"} address`, { variant: "error" });
    },
  });

  return (
    <Fragment>
      <Typography component="h1" variant="h5" gutterBottom sx={{ pb: "10px" }}>
        {heading}
      </Typography>
      <Formik
        initialValues={{
          ...initialValues,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await onSave(values);
          setSubmitting(false);
        }}
      >
        <FormikForm>
          <FormikTextField id="name" name="name" label="Address Name (Unique)" required fullWidth />
          <FormikTextField id="first_name" name="first_name" label="First Name" required fullWidth />
          <FormikTextField id="last_name" name="last_name" label="Last Name" required fullWidth />
          <CompanyFields />
          <FormikTextField id="address1" name="address1" label="Address Line 1" required fullWidth />
          <FormikTextField id="address2" name="address2" label="Address Line 2" fullWidth />
          <FormikTextField id="city" name="city" label="City" required fullWidth />
          <FormikTextField id="state_province" name="state_province" label="State/Province" required fullWidth />
          <FormikTextField id="zip_postal_code" name="zip_postal_code" label="ZIP/Postal Code" required fullWidth />
          <FormikSelect id="country" name="country" label="Country" required>
            <MenuItem value="US">United States</MenuItem>
            <MenuItem value="CA">Canada</MenuItem>
            <MenuItem value="MX">Mexico</MenuItem>
            <MenuItem value="UK">United Kingdom</MenuItem>
            <MenuItem value="DE">Germany</MenuItem>
            <MenuItem value="FR">France</MenuItem>
            <MenuItem value="ES">Spain</MenuItem>
            <MenuItem value="IT">Italy</MenuItem>
            <MenuItem value="JP">Japan</MenuItem>
            <MenuItem value="CN">China</MenuItem>
            <MenuItem value="IN">India</MenuItem>
            <MenuItem value="BR">Brazil</MenuItem>
            <MenuItem value="RU">Russia</MenuItem>
            <MenuItem value="AU">Australia</MenuItem>
            <MenuItem value="SE">Sweden</MenuItem>
            <MenuItem value="NL">Netherlands</MenuItem>
            <MenuItem value="TR">Turkey</MenuItem>
            <MenuItem value="CH">Switzerland</MenuItem>
            <MenuItem value="DO">Dominican Republic</MenuItem>
            <MenuItem value="ZA">South Africa</MenuItem>
            <MenuItem value="EG">Egypt</MenuItem>
          </FormikSelect>
          <PhoneFields />
          <FormikTextField id="email" name="email" label="Email Address" required fullWidth />
          <FormikCheckbox id="is_default" name="is_default" label="Set as default address" />
          <FormikSubmitButton variant="contained" aria-label="Save address">
            {edit ? "Save Changes" : "Save"}
          </FormikSubmitButton>
          <Button color="error" component={Link} to={`/account/address-book/`} aria-label="Cancel address changes">
            Cancel
          </Button>
        </FormikForm>
      </Formik>
    </Fragment>
  );
}

function CompanyFields() {
  const [{ value: isCompanyDomain }] = useField<boolean>("is_company");
  return (
    <Fragment>
      {isCompanyDomain && (
        <Fragment>
          <FormikTextField
            id="company_name"
            name="company_name"
            label="Company Name"
            required={isCompanyDomain}
            fullWidth
          />
          <FormikTextField id="job_title" name="job_title" label="Job Title" required={isCompanyDomain} fullWidth />
        </Fragment>
      )}
      <FormikCheckbox id="is_company" name="is_company" label="Is company address" />
    </Fragment>
  );
}

function PhoneFields() {
  const [{ value: hasPhoneExt }] = useField<boolean>("has_phone_ext");
  return (
    <Fragment>
      <Grid columnGap={2} rowGap={2} container>
        <Grid item xs={12}>
          <label htmlFor={"phone"}>Phone Number</label>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormikSelect id="area_code" name="area_code" required>
            <MenuItem value="+1">+1</MenuItem>
            <MenuItem value="+44">+44</MenuItem>
            <MenuItem value="+49">+49</MenuItem>
            <MenuItem value="+33">+33</MenuItem>
            <MenuItem value="+34">+34</MenuItem>
            <MenuItem value="+39">+39</MenuItem>
            <MenuItem value="+81">+81</MenuItem>
            <MenuItem value="+86">+86</MenuItem>
          </FormikSelect>
        </Grid>
        <Grid item xs={12} sm>
          <FormikTextField id="phone" name="phone" label="Phone Number" required fullWidth />
        </Grid>
        <Grid item xs={12} sm={hasPhoneExt ? 3 : 0}>
          {hasPhoneExt && (
            <FormikTextField id="phone_ext" name="phone_ext" label="Extension" fullWidth required={hasPhoneExt} />
          )}
        </Grid>
      </Grid>
      <FormikCheckbox id="has_phone_ext" name="has_phone_ext" label="Phone has extension" />
    </Fragment>
  );
}
