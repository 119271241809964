import { RemoveCircle } from "@mui/icons-material";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { PaymentMethod } from "Services/api/cards/interfaces";

interface IListPaymentMethod {
  paymentMethods: PaymentMethod[];
  onDelete: (id: string) => void;
  onDefault: (id: string) => void;
}

export function ListPaymentMethod({ paymentMethods, onDelete, onDefault }: IListPaymentMethod): JSX.Element {
  const confirm = useConfirm();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 550, textAlign: "center" }} aria-label="Payment Methods">
        <TableHead>
          <TableRow>
            <TableCell>Remove</TableCell>
            <TableCell>Last 4 digits</TableCell>
            <TableCell>Date</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentMethods.map((paymentMethod) => (
            <TableRow key={paymentMethod.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell scope="row">
                <Button
                  data-testid={`${paymentMethod.id}_remove`}
                  onClick={() => {
                    void confirm({
                      title: "Delete payment method",
                      description: "Are you sure you want to remove this payment method?",
                    }).then(() => onDelete(paymentMethod.id));
                  }}
                >
                  <RemoveCircle />
                </Button>
              </TableCell>
              <TableCell>{paymentMethod.card.last4}</TableCell>
              <TableCell>{new Date(paymentMethod.created * 1000).toLocaleDateString()}</TableCell>
              <TableCell align="center">
                {paymentMethod.default ? (
                  "Default"
                ) : (
                  <Button
                    data-testid={`${paymentMethod.id}_default`}
                    onClick={() => {
                      void confirm({
                        title: "Set default payment method",
                        description: "Are you sure you want to set this payment method as the default?",
                      }).then(() => onDefault(paymentMethod.id));
                    }}
                  >
                    Set as default
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
