import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { updateInstances } from "Pages/ServicePage/ServicePage.slice";
import { ServiceInstance } from "Services/api/services/interfaces";
import { deleteServiceInstance } from "Services/api/services/services";
import { ProviderContext, useSnackbar } from "notistack";
import { SetStateAction, useState } from "react";
import { store } from "../../../state/store";

export type OnInstanceClickedEvent = (service: string, serviceUser: string, tenant: string) => unknown;

export function ServiceInstancesTable({
  service,
  serviceInstances,
  onInstanceClicked,
  includeRemove = false,
}: {
  service: string;
  serviceInstances: ServiceInstance[];
  onInstanceClicked: OnInstanceClickedEvent;
  includeRemove?: boolean;
}) {
  const [isRemoveLinkDialogOpen, setIsRemoveLinkDialogOpen] = useState(false);
  const [toRemove, setToRemove] = useState({
    serviceUser: "",
    tenant: "",
    index: -1,
  });

  const handleClose = handleRemoveLinkClose(setIsRemoveLinkDialogOpen, serviceInstances);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 550 }} aria-label="Instances">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Tenant</TableCell>
              {includeRemove ? (
                <TableCell align="center" size="small" sx={{ width: "100px" }}>
                  Remove
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceInstances.map(({ serviceUser, tenant }, index) => (
              <TableRow
                key={tenant + serviceUser}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                style={{ cursor: "pointer" }}
              >
                <TableCell onClick={() => onInstanceClicked(service, serviceUser, tenant)} component="th" scope="row">
                  {serviceUser}
                </TableCell>
                <TableCell onClick={() => onInstanceClicked(service, serviceUser, tenant)}>{tenant}</TableCell>
                {includeRemove ? (
                  <TableCell
                    onClick={() => {
                      setToRemove({
                        serviceUser,
                        tenant,
                        index,
                      });
                      setIsRemoveLinkDialogOpen(true);
                    }}
                    align="center"
                    size="small"
                    sx={{ width: "100px" }}
                    data-testid="remove-link"
                  >
                    <DeleteIcon />
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <RemoveLinkDialog
        open={isRemoveLinkDialogOpen}
        handleClose={handleClose}
        service={service}
        toRemove={toRemove}
        index={toRemove.index}
      />
    </>
  );
}

function handleRemoveLinkClose(
  setIsRemoveLinkDialogOpen: (value: SetStateAction<boolean>) => void,
  serviceInstances: ServiceInstance[]
) {
  return (index = -1) => {
    setIsRemoveLinkDialogOpen(false);
    if (index > -1) {
      serviceInstances.splice(index, 1);
      store.dispatch(
        updateInstances({
          serviceInstances,
        })
      );
    }
  };
}

export function RemoveLinkDialog({
  open,
  handleClose,
  service,
  toRemove: { serviceUser, tenant },
  index,
}: {
  open: boolean;
  handleClose: (index?: number) => void;
  service: string;
  toRemove: {
    serviceUser: string;
    tenant: string;
  };
  index: number;
}) {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <div>{`Are you sure you want to remove link to ${tenant} for ${serviceUser}?`}</div>
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button
          data-testid="submit-remove"
          type="submit"
          onClick={() => {
            void deleteLink(service, serviceUser, tenant, enqueueSnackbar, index, handleClose)();
          }}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function deleteLink(
  service: string,
  username: string,
  tenant: string,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  index: number,
  handleClose: (index?: number) => void
) {
  return async () => {
    const response = await deleteServiceInstance(service, username, tenant);

    if (response.status === "fail") {
      enqueueSnackbar(response.data, { variant: "error" });
      return;
    }

    enqueueSnackbar(response.data, { variant: "success" });
    handleClose(index);
  };
}
