import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import logo from "Public/NeoscloudLogo.png";
import { Link as RouterLink } from "react-router-dom";

export function NotFound() {
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <ButtonBase component={RouterLink} to="/">
        <Box src={logo} sx={{ width: 300 }} component="img" alt="Nescloud logo" />
      </ButtonBase>
      <Typography component="h1" sx={{ fontSize: "3rem", fontWeight: "bold" }}>
        404
      </Typography>
      <Typography component="h2" sx={{ fontSize: "2rem" }}>
        Page not found
      </Typography>
      <Typography component="p" sx={{ textAlign: "center" }}>
        The page you are looking for doesn&apos;t exist or an error ocurred. 😔
      </Typography>
    </Stack>
  );
}
