import { FailedJsendResponse, JsendResponse, failedJsendResponseDecoder, jsendResponseDecoder } from "Types/jsend";
import { eitherDecoder } from "Types/monads";
import { DecoderType, array, boolean, dict, object, string } from "decoders";

export const failedRegisterResponseDecoder = eitherDecoder(dict(array(string)), string);

export type FailedRegisterResponse = DecoderType<typeof failedRegisterResponseDecoder>;

export const registerResponseDecoder = jsendResponseDecoder(string, failedRegisterResponseDecoder);

export type RegisterResponse = DecoderType<typeof registerResponseDecoder>;

export const defaultUserChangeResponseDecoder = jsendResponseDecoder(string, failedJsendResponseDecoder);

export type DefaultUserChangeResponse = DecoderType<typeof defaultUserChangeResponseDecoder>;

const userBase = {
  username: string,
  firstName: string,
  lastName: string,
};

export const userNamesDecoder = object(userBase);

export type UserNames = DecoderType<typeof userNamesDecoder>;

export type UserNamesResponse = JsendResponse<UserNames, FailedJsendResponse>;
export const userNamesResponseDecoder = jsendResponseDecoder(userNamesDecoder, failedJsendResponseDecoder);

export const userDataDecoder = object({
  ...userBase,
  avatar: string,
  subscriptions: object({
    neosmail: boolean,
    neosdrive: boolean,
  }),
});

export type UserData = DecoderType<typeof userDataDecoder>;

export type UserDataResponse = JsendResponse<UserData, FailedJsendResponse>;
export const userDataResponseDecoder = jsendResponseDecoder(userDataDecoder, failedJsendResponseDecoder);

export interface UserPasswordChange {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface BaseRegisterWithEmailPayload {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface RegisterWithEmailGenerateCodePayload extends BaseRegisterWithEmailPayload {
  reCaptchaToken: string;
}

export interface RegisterWithEmailPayload extends BaseRegisterWithEmailPayload {
  code: string;
}
