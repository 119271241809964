import { Container, Grid, Link, Stack, Typography } from "@mui/material";
import { Copyright } from "@nc/neoscloud-common-react";
import { PropsWithChildren } from "react";

export type InputNames = "firstName" | "lastName" | "email" | "username" | "password" | "confirm";

export type RegistrationFormData = {
  [key in InputNames]: string;
};

export interface RegistrationRequestData extends Omit<RegistrationFormData, "email" | "username"> {
  email?: string;
  username?: string;
  emailConfirmCode?: string;
}

export function FormContainer({ children, logo, title }: PropsWithChildren<{ logo: React.ReactNode; title: string }>) {
  return (
    <Container component="main" maxWidth="sm" sx={{ marginTop: 2 }}>
      <Stack
        sx={{
          border: "1px solid rgb(218, 220, 224)",
          borderRadius: "8px",
          padding: "40px",
        }}
        alignItems="center"
      >
        {logo}
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        {children}
      </Stack>
      <Grid container justifyContent="flex-end" flexWrap={"wrap"} sx={{ marginBottom: "25px" }}>
        <Grid item sx={{ paddingRight: "20px" }}>
          <Link href="#" underline="none">
            Help
          </Link>
        </Grid>
        <Grid item sx={{ paddingRight: "20px" }}>
          <Link href="#" underline="none">
            Privacy
          </Link>
        </Grid>
        <Grid item>
          <Link href="#" underline="none">
            Terms
          </Link>
        </Grid>
      </Grid>
      <Copyright />
    </Container>
  );
}
