import { PropsWithChildren } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

function BillingContainer({ children, index }: PropsWithChildren<{ index: number }>) {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={index} aria-label="billing links" centered variant="fullWidth">
          <Tab label="Payment Methods" onClick={() => navigate("/billing/payment")} />
          <Tab label="Invoices" onClick={() => navigate("/billing/invoices")} />
          <Tab label="Subscriptions" onClick={() => navigate("/billing/subscriptions")} />
        </Tabs>
      </Box>
      <section>{children}</section>
    </Fragment>
  );
}

export default BillingContainer;
