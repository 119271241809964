import { Action, configureStore } from "@reduxjs/toolkit";
import servicePage from "Pages/ServicePage/ServicePage.slice";

const middlewareConfiguration = { serializableCheck: false };

export const store = configureStore({
  reducer: { servicePage },
  devTools: {
    name: "Neos Account",
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(middlewareConfiguration),
});
export type State = ReturnType<typeof store.getState>;

export function dispatchOnCall(action: Action) {
  return () => store.dispatch(action);
}
