import { CircularProgress } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Service } from "Services/api/services/interfaces";
import {
  getServiceByName,
  getServiceInstanceByName,
  getServiceInstances,
  getTenantServiceToken,
} from "Services/api/services/services";
import { ServiceInstancesTable } from "Shared/ServiceInstancesTable/ServiceInstancesTable";
import { closeInMilliseconds } from "Types/window";
import { ProviderContext, useSnackbar } from "notistack";
import { curry } from "ramda";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { store } from "../../../state/store";
import { useStore } from "../../../state/storeHooks";
import { initializeServicePage, loadService } from "../ServicePage/ServicePage.slice";

export function ServiceLoginPage() {
  const { loadedContent } = useStore(({ servicePage }) => servicePage);
  const { name } = useParams<{ name: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams({});

  useEffect(() => {
    void onLoadServiceLoginPage(enqueueSnackbar, name as string, searchParams);
  }, [name]);

  return (
    <Stack direction="column" spacing={2} alignItems="center" sx={{ padding: "32px", flex: 1, minWidth: 650 }}>
      {loadedContent.match({
        none: () => <CircularProgress />,
        some: ({ service: { name }, serviceInstances }) => (
          <Stack
            direction="column"
            spacing={2}
            sx={{ border: "1px solid #00000029", borderRadius: "4px", padding: "32px" }}
          >
            <Typography textAlign="center">Choose the username and tenant you want to Log In to:</Typography>

            <ServiceInstancesTable
              service={name}
              serviceInstances={serviceInstances}
              onInstanceClicked={sendTokenToOpenerAndClose(enqueueSnackbar)}
            ></ServiceInstancesTable>
          </Stack>
        ),
      })}
    </Stack>
  );
}

async function onLoadServiceLoginPage(
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  name: string,
  searchParams: URLSearchParams
) {
  store.dispatch(initializeServicePage());

  const serviceResult = await getServiceByName(name);

  if (serviceResult.status === "fail") {
    enqueueSnackbar(serviceResult.data);
    notifyAndClose(enqueueSnackbar);
    return;
  }

  const tenant = searchParams.get("tenant");
  if (tenant) await generateServiceInstanceToken(enqueueSnackbar, serviceResult.data, tenant);
  else await loadServiceInstances(enqueueSnackbar, serviceResult.data);
}

async function generateServiceInstanceToken(
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  service: Service,
  tenant: string
) {
  const instanceResult = await getServiceInstanceByName(service.id, tenant);
  if (instanceResult.status === "fail") {
    enqueueSnackbar(instanceResult.data, { variant: "error" });
    notifyAndClose(enqueueSnackbar);
    return;
  }

  const serviceInstance = instanceResult.data;
  await sendTokenToOpenerAndClose(enqueueSnackbar, service.name, serviceInstance.serviceUser, serviceInstance.tenant);
}

async function loadServiceInstances(enqueueSnackbar: ProviderContext["enqueueSnackbar"], service: Service) {
  const instancesResult = await getServiceInstances(service.id);

  if (instancesResult.status === "fail") {
    enqueueSnackbar("An error ocurred loading linked services", { variant: "error" });
    notifyAndClose(enqueueSnackbar);
    return;
  }

  if (instancesResult.data.length === 0) {
    enqueueSnackbar("No linked instances, Please go to your Neos Account and link your users and tenants");
    notifyAndClose(enqueueSnackbar);
    return;
  }

  store.dispatch(
    loadService({
      service,
      serviceInstances: instancesResult.data,
    })
  );
}

function notifyAndClose(enqueueSnackbar: ProviderContext["enqueueSnackbar"]) {
  enqueueSnackbar("This Tab will close in 5 seconds");
  closeInMilliseconds(5000);
}

const sendTokenToOpenerAndClose = curry(async function (
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  service: string,
  serviceUser: string,
  tenant: string
) {
  const result = await getTenantServiceToken(service, serviceUser, tenant);

  if (result.status === "fail") {
    enqueueSnackbar(result.data);
    notifyAndClose(enqueueSnackbar);
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  if (service === "NeosCRM") window.opener.postMessage(result.data, "*");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  else if (service === "NeosGoal") window.opener.postMessage({ tenant, token: result.data }, "*");
  close();
});
