import { jsendResponseDecoder } from "Types/jsend";
import { DecoderType, array, boolean, email, nullable, number, object, string } from "decoders";

const addressDecoder = object({
  id: nullable(number),
  name: string,
  is_default: boolean,
  first_name: string,
  last_name: string,
  company_name: string,
  job_title: string,
  is_company: boolean,
  address1: string,
  address2: string,
  city: string,
  state_province: string,
  zip_postal_code: string,
  country: string,
  area_code: string,
  phone: string,
  phone_ext: string,
  has_phone_ext: boolean,
  email: email,
});
export type Address = DecoderType<typeof addressDecoder>;

export const getAddressResponseDecoder = jsendResponseDecoder(addressDecoder, string);
export type GetAddressResponse = DecoderType<typeof getAddressResponseDecoder>;

export const getAddressesResponseDecoder = jsendResponseDecoder(array(addressDecoder), string);
export type GetAddressesResponse = DecoderType<typeof getAddressesResponseDecoder>;
