import { JsendSuccess, jsendSuccessDecoder } from "Types/jsend";
import { Decoder, DecoderType, array, boolean, constant, either, number, object, string } from "decoders";

const listSubscriptionDecoder = object({
  id: string,
  service: string,
  description: string,
  amount: number,
  status: either(
    constant("active"),
    constant("canceled"),
    constant("incomplete"),
    constant("incomplete_expired"),
    constant("trialing"),
    constant("past_due"),
    constant("unpaid")
  ),
  statusLabel: string,
  isCloudSubscription: boolean,
  cancelAtPeriodEnd: boolean,
  currentPeriodEnd: string,
});

export type ListSubscription = DecoderType<typeof listSubscriptionDecoder>;

export type ListSubscriptionResponse = JsendSuccess<ListSubscription>;
export type ListSubscriptionsResponse = JsendSuccess<ListSubscription[]>;

export const listSubscriptionResponseDecoder: Decoder<ListSubscriptionResponse> =
  jsendSuccessDecoder(listSubscriptionDecoder);

export const listSubscriptionsResponseDecoder: Decoder<ListSubscriptionsResponse> = jsendSuccessDecoder(
  array(listSubscriptionDecoder)
);
