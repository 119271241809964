import { Box, Grid, Link } from "@mui/material";
import { FormContainer, LoginForm, LoginState } from "@nc/neoscloud-common-react";
import useSingleEffect from "Hooks/useSingleEffect";
import logo from "Public/LockLogo.png";
import { login } from "Services/api/auth/auth";
import { FailedLoginResponse } from "Services/api/auth/interfaces";
import { validSession } from "Services/api/users/users";
import { JsendFail } from "Types/jsend";
import { showSnackbarErrorMessage, showSnackbarErrorsObject } from "Types/snackbar";
import { ProviderContext, useSnackbar } from "notistack";
import { NavigateFunction, Link as RouterLink, useNavigate, useSearchParams } from "react-router-dom";

export function LoginPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});

  useSingleEffect(() => void autoLogin(navigate, searchParams));

  return (
    <FormContainer
      title="Sign in to Neoscloud Services"
      logo={
        <Box
          src={logo}
          sx={{
            width: "60px",
            paddingBottom: "20px",
          }}
          component="img"
          alt="Nescloud logo"
        />
      }
    >
      <LoginPageForm />
    </FormContainer>
  );
}

function LoginPageForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});

  return (
    <LoginForm
      onLogin={async ({ username, password, keepSignedIn }: LoginState) => {
        await onLogin(username, password, keepSignedIn, enqueueSnackbar, navigate, searchParams);
      }}
    >
      <Grid item xs>
        <Link component={RouterLink} to="/restore" variant="body2">
          Forgot password?
        </Link>
      </Grid>
      <Grid item>
        <Link component={RouterLink} to="/signup" variant="body2">
          {"Don't have an account? Sign Up"}
        </Link>
      </Grid>
    </LoginForm>
  );
}

async function onLogin(
  username: string,
  password: string,
  keepSignedIn: boolean,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  navigate: NavigateFunction,
  searchParams: URLSearchParams
) {
  const result = await login(username, password, keepSignedIn);

  if (result.status === "fail") {
    handleFailedLogin(result, enqueueSnackbar);
    return;
  }

  enqueueSnackbar(result.data, {
    variant: "success",
  });

  const service = searchParams.get("service");
  if (service !== null) {
    const tenant = searchParams.get("tenant");
    navigate(`../serviceLogin/${service}${tenant ? `?tenant=${tenant}` : ""}`);
    return;
  }
  const next = searchParams.get("next");
  if (next) navigate(next);
  else navigate("../dashboard");
}

function handleFailedLogin(
  result: JsendFail<FailedLoginResponse>,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"]
) {
  result.data.match({
    left: showSnackbarErrorsObject(enqueueSnackbar),
    right: showSnackbarErrorMessage(enqueueSnackbar),
  });
}

async function autoLogin(navigate: NavigateFunction, searchParams: URLSearchParams) {
  const isValid = await validSession();
  if (isValid) {
    const service = searchParams.get("service");
    if (service !== null) {
      const tenant = searchParams.get("tenant");
      navigate(`../serviceLogin/${service}${tenant ? `?tenant=${tenant}` : ""}`);
      return;
    }
    navigate("../dashboard");
  }
}
