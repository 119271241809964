function loadViewHeightProperty() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

export function setupViewHeight() {
  loadViewHeightProperty();

  window.addEventListener("resize", loadViewHeightProperty);
}
